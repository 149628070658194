/* ------------------------------------------------------------------------------------------------------------------------ */
/* TESTIMONIALS ----------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.pb-block-testimonials {
}
    .pb-block-testimonials-wrapper {
        @include flex-row;
        flex-wrap:wrap;
        justify-content:center;

        @media screen and (min-width:769px) {
            align-items:center;
        }
    }
        .testimonials-slider {

            @media screen and (min-width:769px) {
                width:calc(100% - 192px); max-width:map-get($widths, _medium_large);
            }
            @media screen and (max-width:768px) {
                width:100%;
                order:-1;
            }
        }
            .testimonial-slide.swiper-slide {
                height:auto;

                .testimonial-wrapper {
                    @include flex-column;
                    justify-content:center;
                    padding:0 14px;
                    font-weight:500;
                    height:100%;
                }
                    .testimonial {
                        line-height:45px;
                        font-size:32px;
                        text-indent:-14px;

                        @media screen and (max-width:768px) {
                            line-height:map-get($line_heights, _small);
                            font-size:map-get($font_sizes, _base);
                        }
                    }
                        .testimonial-text {
                            @media screen and (max-width:768px) {
                                width:100%;
                                text-align:center;
                            }
                        }
                    .testimonial-author {
                        padding-top:32px;

                        @media screen and (max-width:768px) {
                            padding-top:24px;
                            text-align:center;
                        }
                    }
            }


/*
 * Slider
 */

.testimonials-slider_prev,
.testimonials-slider_next {

    @media screen and (max-width:768px) {
        margin-top:48px;
    }
}
.testimonials-slider_next {

    @media screen and (max-width:768px) {
        order:2;
    }
}

.testimonials-slider_pagination {

    @media screen and (max-width:768px) {
        width:auto;
    }
}


/*
 * Background
 */

.pb-block-testimonials-bg {
    background:#000;
}
    .pb-block-testimonials-img {
        opacity:.48 !important;
    }
