/* ------------------------------------------------------------------------------------------------------------------------ */
/* PRELOAD ---------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.preload * {
    transition: none !important;
}


/* ------------------------------------------------------------------------------------------------------------------------ */
/* WRAPPER ---------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.wrapper {
    @include animate;
    @include flex-column;
    flex-shrink:0;
    margin-top:map-get($header_height, _desktop);
    min-height:calc(100vh - #{map-get($header_height, _desktop)});
    overflow:hidden;

    &.has-header-lang {
        margin-top:calc(#{map-get($header_height, _desktop)} + 62px);
        min-height:calc(100vh - (#{map-get($header_height, _desktop)} + 62px));
    }

    // Mobile
    @media screen and (max-width:768px) {
        margin-top:map-get($header_height, _mobile);
        min-height:calc(100vh - #{map-get($header_height, _mobile)});

        &.has-header-lang {
            margin-top:calc(#{map-get($header_height, _mobile)} + 56px);
            min-height:calc(100vh - (#{map-get($header_height, _mobile)} + 56px));
        }
    }
}


/* ------------------------------------------------------------------------------------------------------------------------ */
/* MAIN CONTAINER --------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.main-container {
    @include flex-column;
    flex:1 1 auto;
}
