/* ------------------------------------------------------------------------------------------------------------------------ */
/* BOX -------------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.box {
    @include flex-column;
    align-items:center;
}
    .box-content {
        width:100%;
    }
