/* ------------------------------------------------------------------------------------------------------------------------ */
/* TEXT MEDIA ------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.pb-block-tm {

    @media screen and (max-width:768px) {

        & > .pb-block_content > .g-grid {

            &.grid_reverse .g-column:first-child,
            &:not(.grid_reverse) .g-column:last-child {
                margin-top:32px;
            }
        }
    }

    // Has border
    &.has-border {

        @media screen and (min-width:769px) {

            .pb-block-tm-text {
                @include relative;
                flex:1 1 auto;
                border-top:2px solid currentColor;

                &:after {
                    @include absolute;
                    content:"";
                    bottom:0;
                    width:10px; height:10px;
                    background:currentColor;
                    border-radius:100%;
                }
            }

            .pb-block-tm-media {
                height:100%;
                overflow:hidden;

                .media-picture,
                .media-img {
                    height:100%;
                }
                .media-img {
                    object-fit:cover; font-family:'object-fit:cover;';
                }
            }

            .pb-block_content > .g-grid.grid_reverse {

                .pb-block-tm-text {
                    padding:96px 96px 0 72px;
                    border-right:2px solid currentColor;
                    border-top-right-radius:96px;

                    &:after {
                        right:-6px;
                    }
                }

                .pb-block-tm-media {
                    border-bottom-left-radius:96px;
                }
            }

            .pb-block_content > .g-grid:not(.grid_reverse) {

                .pb-block-tm-text {
                    padding:96px 72px 0 96px;
                    border-left:2px solid currentColor;
                    border-top-left-radius:96px;

                    &:after {
                        left:-6px;
                    }
                }

                .pb-block-tm-media {
                    border-bottom-right-radius:96px;
                }
            }
        }
        @media screen and (max-width:1220px) and (min-width:769px) {

            .pb-block_content > .g-grid.grid_reverse {

                .pb-block-tm-text {
                    padding:48px 48px 0 24px;
                    border-top-right-radius:48px;
                }

                .pb-block-tm-media {
                    border-bottom-left-radius:48px;
                }
            }

            .pb-block_content > .g-grid:not(.grid_reverse) {

                .pb-block-tm-text {
                    padding:48px 24px 0 48px;
                    border-top-left-radius:48px;
                }

                .pb-block-tm-media {
                    border-bottom-right-radius:48px;
                }
            }
        }
        @media screen and (max-width:768px) {

            .pb-block-tm-media {
                overflow:hidden;
            }

            .pb-block_content > .g-grid.grid_reverse .pb-block-tm-media {
                border-bottom-left-radius:48px;
            }
            .pb-block_content > .g-grid:not(.grid_reverse) .pb-block-tm-media {
                border-bottom-right-radius:48px;
            }
        }
    }

    // Has extend media
    &.has-extend-media {

        @media screen and (min-width:769px) {

            &:first-child {
                padding-top:0;
            }

            & > .pb-block_content > .g-grid > .g-column:first-child {
                align-self:center;
            }

            .pb-block-tm-media {
                width:calc(50vw - 48px); height:100%;
                overflow:hidden;

                .media-picture,
                .media-img {
                    height:100%;
                }
                .media-img {
                    object-fit:cover; font-family:'object-fit:cover;';
                }
            }

            .pb-block_content > .g-grid.grid_reverse .pb-block-tm-media {
                margin-left:-96px;
                border-bottom-right-radius:48px;

                @media screen and (min-width:1722px) {
                    margin-left:calc(-1 * ((100vw - 1530px) / 2));
                }
            }
            .pb-block_content > .g-grid:not(.grid_reverse) .pb-block-tm-media {
                border-bottom-left-radius:48px;
            }
        }
    }
}
