/* ------------------------------------------------------------------------------------------------------------------------ */
/* THEME COLORS ----------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

$colors: (
    _white: #FFFFFF,
    _01-01: #000000, // Black
    _01-02: #707070, // Gray
    _01-03: #DADADA, // Light Gray
    _01-04: #F6F6F6, // Lightest Gray
    _02-01: #003445, // Petrol Blue
    _02-02: #00567A, // Teal Blue
    _03-01: #008170, // Emerald
    _04-01: #695989, // Purple
    _04-02: #8F175C, // Dark Magenta
    _04-03: #E50051, // Magenta
    _05-01: #EE7444, // Orange
    _06-01: #FBBB21, // Yellow
    _error: #D91E18,
    _alert: #F89406,
    _valid: #00B16A,
    _update: #1E8BC3,
    _twitter: #2EA7FF,
    _linkedin: #273FC4,
    _facebook: #40446C,
);

$bc_gradient: linear-gradient(45deg, rgba(map-get($colors, _02-02), 1) 0%, rgba(map-get($colors, _02-01), 1) 50%, rgba(map-get($colors, _02-02), 1) 100%);


/* ------------------------------------------------------------------------------------------------------------------------ */
/* WIDTHS ----------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

$widths: (
    _large: 1622px,
    _base: 1530px,
    _medium: 1440px,
    _medium_large: 1220px,
    _medium_small: 780px,
    _small: 680px,
    _xsmall: 480px
);

$header_height: (
    _desktop: 241px,
    _mobile: 94px
);


/* ------------------------------------------------------------------------------------------------------------------------ */
/* LINE HEIGHTS ----------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

$line_heights: (
    _base: 30px,
    _small: 25px
);


/* ------------------------------------------------------------------------------------------------------------------------ */
/* FONT SIZES ------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

$font_sizes: (
    _base: 20px,
    _small: 18px
);


/* ------------------------------------------------------------------------------------------------------------------------ */
/* FONT FAMILIES ---------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

// Light
@font-face {
    font-family:'Montserrat';
    font-style:normal;
    font-weight:300;
    font-display:swap;
    src:url(../fonts/montserrat/300.woff2) format('woff2');
    unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

// Regular
@font-face {
    font-family:'Montserrat';
    font-style:normal;
    font-weight:400;
    font-display:swap;
    src:url(../fonts/montserrat/400.woff2) format('woff2');
    unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

// Medium
@font-face {
    font-family:'Montserrat';
    font-style:normal;
    font-weight:500;
    font-display:swap;
    src:url(../fonts/montserrat/500.woff2) format('woff2');
    unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

// Semibold
@font-face {
    font-family:'Montserrat';
    font-style:normal;
    font-weight:600;
    font-display:swap;
    src:url(../fonts/montserrat/600.woff2) format('woff2');
    unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

// Bold
@font-face {
    font-family:'Montserrat';
    font-style:normal;
    font-weight:700;
    font-display:swap;
    src:url(../fonts/montserrat/700.woff2) format('woff2');
    unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

$font_families: (
    _01: ('Montserrat', sans-serif)
);
