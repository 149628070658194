/* ------------------------------------------------------------------------------------------------------------------------ */
/* BREADCRUMBS --------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.pb-block-breadcrumbs {
    @include relative(2);
    padding-top:24px; padding-bottom:48px;

    @media screen and (max-width:768px) {
        padding-top:18px;

        &.pb-block:not(.pb-block_has-bg) + .pb-block:not(.pb-block_has-bg):not(.pb-block-spacer) {
            margin-top:0 !important;
        }
    }
}
