/* ------------------------------------------------------------------------------------------------------------------------ */
/* CTA BAND --------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.pb-block-cta-band {
    padding-top:0; padding-bottom:0;

    &.pb-block_has-triangle {

        @media screen and (max-width:768px) {
            padding-top:48px;
        }
    }

    .pb-block_content {
        @include relative;

        @media screen and (min-width:769px) {
            width:50%; max-width:map-get($widths, _medium_small);
        }
        @media screen and (max-width:768px) {
            text-align:center;
        }
    }
}
    .pb-block-cta-band-lhc {
        justify-content:center;
    }
        .pb-block-cta-band-logo {

            @media screen and (min-width:769px) {
                @include absolute;
                top:-36px; left:-46px;
                width:48px; height:48px;
            }
            @media screen and (max-width:768px) {
                padding-bottom:18px;
            }

            svg {
                display:block;
                width:48px; height:48px;

                @media screen and (max-width:768px) {
                    margin:0 auto;
                }
            }
        }
        .pb-block-cta-band-heading {
            line-height:64px;
            color:map-get($colors, _01-01);
            font-size:52px;

            @media screen and (max-width:1220px) and (min-width:769px) {
                line-height:48px;
                font-size:44px;
            }
            @media screen and (max-width:768px) {
                line-height:42px;
                font-size:34px;
            }
        }
        .pb-block-cta-band-text {
            padding-top:24px;
        }
        .pb-block-cta-band-ctas-wrapper {
            padding-top:48px !important;

            @media screen and (max-width:768px) {
                padding-top:24px !important;
                align-items:center !important;
            }

            .btn {
                min-width:fit-content;
                white-space:nowrap;
            }
        }


/*
 * Background
 */

.pb-block-cta-band-bg {

    @media screen and (min-width:769px) {
        @include full-size(-1);
        overflow:hidden;
    }
    @media screen and (max-width:768px) {
        margin:24px -24px 0;
        width:calc(100% + 48px);
    }
}
    .pb-block-cta-band-bg-picture {

        @media screen and (min-width:769px) {
            @include absolute;
            @include flex-row;
            top:0; left:50%; right:0; bottom:0;
        }
        @media screen and (max-width:768px) {
            @include relative;
        }

        &:before {
            @include aspect-ratio('1080 / 1920'); // Aspect ratio: 16:9
        }
    }
        .pb-block-cta-band-bg-img {
            @include full-size;
            position:absolute !important;
            height:100% !important;
            object-fit:cover; object-position:left center; font-family:'object-fit:cover; object-position:left center;';
            pointer-events:none !important;
        }


/*
 * Ad-hoc
 */

.pb-block-spacer +  .pb-block-cta-band {

    @media screen and (max-width:768px) {
        margin-top:-24px;
    }
}
