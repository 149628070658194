/* ------------------------------------------------------------------------------------------------------------------------ */
/* TEAM ------------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.pb-block-team {
}


/*
 * Content
 */

.pb-block-team-content {

    @media screen and (max-width:1220px) {
        padding-top:0; padding-bottom:0;
    }
}
    .pb-block-team-container {
        background:#FFF;
        border-bottom-left-radius:48px;

        @media screen and (max-width:1024px) and (min-width:769px) {

            & > .g-grid {
                flex-direction:column;
                margin:0;

                & > .g-column {
                    padding:0;
                    width:100%;

                    &:last-child {
                        padding-top:48px;
                    }
                }
            }
        }
        @media screen and (max-width:768px) {
            padding-top:48px; padding-bottom:48px;
        }
    }

        .pb-block-team-heading {
            line-height:65px;
            font-size:55px;
            font-weight:700;

            @media screen and (max-width:1220px) and (min-width:769px) {
                line-height:56px;
                font-size:42px;
            }
            @media screen and (max-width:768px) {
                line-height:36px;
                font-size:26px;
            }
        }

        .pb-block-team-text {
            padding-top:48px;
            line-height:33px;
            font-size:27px;
            font-weight:600;

            @media screen and (max-width:768px) {
                padding-top:12px;
                line-height:map-get($line_heights, _small);
                font-size:map-get($font_sizes, _small);
            }
        }

        .pb-block-team-cta-wrapper {
            @include flex-row;
            padding-top:48px;
        }


/*
 * Items
 */

.pb-block-team-members {
    margin-top:-48px;

    @media screen and (max-width:768px) {
        margin-top:24px;
    }
}

    .pb-block-team-member {
        padding-top:48px;
        text-align:center;

        @media screen and (max-width:1220px) and (min-width:1025px) {

            &.g-column {
                width:calc((6 / 12) * 100%);
            }
        }
        @media screen and (max-width:768px) {
            padding-top:24px;
        }
    }
        .pb-block-team-member-picture {
            border-radius:100%;
            overflow:hidden;

            @media screen and (max-width:768px) {
                margin:0 auto;
                width:128px;
            }
        }
        .pb-block-team-member-heading,
        .pb-block-team-member-role {
            line-height:22px;
            font-size:map-get($font_sizes, _small);
        }
        .pb-block-team-member-heading {
            padding-top:24px;

            @media screen and (max-width:768px) {
                padding-top:18px;
            }
        }
        .pb-block-team-member-role {
            font-weight:300;
        }


/*
 * Background
 */

.pb-block-team-bg {
    background:#000;
}
