/* ------------------------------------------------------------------------------------------------------------------------ */
/* NEWSLETTER ------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.pb-block-newsletter {
    padding-top:0; padding-bottom:0;
    color:#FFF;
    background:#000;
    overflow:hidden;
}
    .pb-block-newsletter-lhc {
        justify-content:center;
    }
        .pb-block-newsletter-heading {
            line-height:40px;
            color:map-get($colors, _04-03);
            font-size:32px;
            font-weight:600;
        }
        .pb-block-newsletter-text {
            padding-top:12px;
            line-height:32px;
            font-size:28px;
            font-weight:300;
        }


/*
 * Form
 */

.form-newsletter {
    padding:48px 96px 0 0;
    width:calc(100% - 96px); max-width:map-get($widths, _xsmall);
}
    .form-newsletter-btn {
        margin-top:24px;
    }


/*
 * Image
 */

.pb-block-newsletter-picture {
    margin:0 auto; padding-top:48px;
    width:100%; max-width:400px;
}


/*
 * Background
 */

.pb-block-newsletter-bg {
    @include full-size(-1);
    overflow:hidden;
}
    .pb-block-newsletter-bg-picture {
        @include absolute;
        @include flex-row;
        top:50%; left:50%; right:0;
        transform:translateY(-50%);

        &:before {

            @media screen and (min-width:769px) {
                @include aspect-ratio('1080 / 1920'); // Aspect ratio: 16:9
            }
            @media screen and (max-width:768px) {
                @include aspect-ratio('576 / 768'); // Aspect ratio: 4:3
            }
        }
    }
        .pb-block-newsletter-bg-img {
            @include full-size;
            position:absolute !important;
            height:100% !important;
            object-fit:cover; font-family:'object-fit:cover;';
            pointer-events:none !important;
        }
