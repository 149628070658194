/* ------------------------------------------------------------------------------------------------------------------------ */
/* SOCIAL MEDIA ----------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.pb-block-social-media {
}


/*
 * Intro
 */

.pb-block-social-media-heading {
    line-height:34px;
    color:map-get($colors, _01-01);
    font-size:28px;
    font-weight:600;
}


/*
 * Tabs
 */

.social-media-tabs {
    padding-top:48px;
}


// Tabs Navigation
.social-media-tabs-nav {
}
    .social-media-tabs-nav-list {
        @include flex-row;
        list-style:none;
    }
        .social-media-tabs-nav-item {
            @include flex-column;

            &:not(:first-child) {
                margin-left:-24px;
            }

            &:nth-child(1) .social-media-tabs-nav-btn {
                background:map-get($colors, _twitter);

                svg {
                    fill:map-get($colors, _twitter);
                }
            }
            &:nth-child(2) .social-media-tabs-nav-btn {
                background:map-get($colors, _linkedin);

                svg {
                    fill:map-get($colors, _linkedin);
                }
            }
            &:nth-child(3) .social-media-tabs-nav-btn {
                background:map-get($colors, _facebook);

                svg {
                    fill:map-get($colors, _facebook);
                }
            }

            &:not(:last-child) .social-media-tabs-nav-btn {
                padding-right:52px;

                @media screen and (max-width:768px) {
                    padding-right:40px;
                }
            }
            &:last-child .social-media-tabs-nav-btn {

                @media screen and (max-width:768px) {
                    border-top-right-radius:24px;
                }
            }
        }
            .social-media-tabs-nav-btn {
                @include animate;
                padding:16px 28px;
                line-height:1;
                color:#FFF;
                border-top-left-radius:24px;

                @media screen and (max-width:768px) {
                    padding:16px;
                }

                .btn-icon {
                    @include flex-column;
                    justify-content:center;
                    align-items:center;
                    width:32px; height:32px;
                    background:#FFF;
                    border-radius:100%;

                    @media screen and (max-width:768px) {
                        margin-right:0 !important;
                    }
                }
            }

// Tabs Blocks
.social-media-block {
    padding:48px;

    @media screen and (min-width:769px) {
        background:rgba(map-get($colors, _01-03), .48);
    }
    @media screen and (max-width:1420px) and (min-width:769px) {

        .social-card.has-logo {
            flex-direction:column;
        }
            .social-card-content {
                padding-top:24px;
            }
    }
    @media screen and (max-width:1110px) and (min-width:769px) {

        .g-grid {
            margin-top:-24px;

            .g-column {
                padding:24px 12px 0 !important;
                width:50%;
            }
        }
    }
    @media screen and (max-width:768px) {
        margin-top:-36px; padding:24px 0 0;
    }
}
