/* ------------------------------------------------------------------------------------------------------------------------ */
/* PAGINATION (ARCHIVE) --------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.pgn-archive {
}

    .pgn-archive_list {
        @include flex-row;
        justify-content:center;
        align-items:center;
        margin:0 auto;
        list-style:none;

        @media screen and (max-width:768px)
        {
            justify-content:space-between;
        }
    }
        .pgn-archive_list-item {
            margin:0 6px;

            @media screen and (min-width:769px)
            {
                &:not(.is-current):not(.is-disabled):hover .pgn-archive_list-item_link,
                &:not(.is-current):not(.is-disabled):focus .pgn-archive_list-item_link {
                    color:map-get($colors, _05-01);

                    svg {
                        fill:map-get($colors, _05-01);
                    }
                }
            }
        }
            .pgn-archive_list-item_link {
                @include animate;
                @include flex-column;
                justify-content:center;
                align-items:center;
                line-height:1;
                color:map-get($colors, _01-01);
                font-size:16px;
            }

// Page
.pgn-archive_page {
    @include flex-column;
    justify-content:center;
    align-items:center;

    @media screen and (max-width:768px) {
        display:none;
    }

    .pgn-archive_list-item_link {
        width:42px; height:42px;
        box-sizing:border-box;
    }

    &.is-current .pgn-archive_list-item_link {
        border:1px solid map-get($colors, _01-03);
    }
}

// Prev / Next
.pgn-archive_prev,
.pgn-archive_next {

    .pgn-archive_list-item_link {
        @include animate;
        @include flex-row;

        @media screen and (max-width:768px)
        {
            padding:0;
        }

        svg {
            @include animate;
            display:block;
            width:12px; height:12px;
            fill:map-get($colors, _01-01);
        }
    }
}

// Prev
.pgn-archive_prev {

    @media screen and (min-width:769px)
    {
        padding-right:6px;
    }

    .pgn-archive_list-item_link svg {
        margin-right:9px;
        transform:rotate(-180deg);
    }
}

// Next
.pgn-archive_next {

    @media screen and (min-width:769px)
    {
        padding-left:6px;
    }

    .pgn-archive_list-item_link svg {
        margin-left:9px;
    }
}
