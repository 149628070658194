/* ------------------------------------------------------------------------------------------------------------------------ */
/* PRODUCT RANGE ---------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.pb-block-product-range {
    padding:0;
}


/*
 * Header
 */

.pb-block-product-range-header {
    background:$bc_gradient;

    @media screen and (max-width:768px) {
        padding-top:48px; padding-bottom:48px;
    }
}

    .pb-block-product-range-heading {
        line-height:44px;
        color:#FFF;
        font-size:36px;
        font-weight:600;

        @media screen and (max-width:768px) {
            line-height:38px;
            font-size:32px;
        }
    }

    .pb-block-product-range-desc {
        padding-top:12px;
    }

    .pb-block-product-range-colors {
        @include flex-row;
        flex-wrap:wrap;
        margin-top:-24px; padding-top:32px;

        @media screen and (max-width:768px) {
            margin-top:-6px;
            width:calc(100% + 6px);
        }
    }
        .pb-block-product-range-color {
            @include animate;
            @include flex-column;
            @include relative;
            flex-shrink:0;
            justify-content:center;
            align-items:center;
            margin-top:24px;
            width:96px; height:96px;
            line-height:1.2;
            color:#FFF;
            font-size:16px;
            // border:1px solid map-get($colors, _01-02);
            border-radius:12px 0 12px 12px;
            box-sizing:border-box;

            &:not(:last-child) {
                margin-right:24px;

                @media screen and (max-width:768px) {
                    margin-right:6px;
                }
            }

            svg {
                @include animate;
                @include absolute;
                flex-shrink:0;
                top:50%; left:50%;
                display:block;
                width:32px; height:32px;
                fill:#FFF;
                opacity:0;
                transform:translate(-50%, -50%);

                @media screen and (max-width:768px) {
                    width:24px; height:24px;
                }
            }

            @media screen and (min-width:769px) {
                &:not(.disabled):not(:disabled):hover,
                &:not(.disabled):not(:disabled):focus {
                    color:transparent;

                    svg {
                        opacity:1;
                    }
                }
            }
            @media screen and (max-width:768px) {
                margin-top:6px;
                width:57px; height:57px;
                font-size:12px;
            }

            &.is-light {
                color:map-get($colors, _01-01);

                svg {
                    fill:map-get($colors, _01-01);
                }
            }

            &.is-active {
                color:transparent;
                cursor:default;

                svg {
                    opacity:1;
                }
            }
        }


/*
 * Products
 */

.pb-block-product-range-wrapper {

    @media screen and (max-width:768px) {
        padding-top:48px; padding-bottom:48px;
    }
}

    .product-range {
    }

        .product-range-group-heading {
            line-height:39px;
            font-size:32px;

            @media screen and (max-width:768px) {
                line-height:32px;
                font-size:24px;
            }
        }

        .product-range-items {
            margin-top:-12px; padding-top:24px;

            @media screen and (max-width:768px) {
                margin-top:-6px;
                justify-content:space-between;
            }

            & > .g-column {

                @media screen and (max-width:1220px) and (min-width:901px) {
                    width:calc((3 / 12) * 100%);
                }
                @media screen and (max-width:900px) and (min-width:769px) {
                    width:calc((4 / 12) * 100%);
                }
                @media screen and (max-width:768px) {
                    width:calc(50% - 3px);
                }
            }
        }
            .product-range-item {
                @include animate;
                @include flex-row;
                @include relative;
                align-items:flex-end;
                margin-top:12px; padding:24px;
                line-height:1.2;
                color:#FFF;
                font-size:16px;
                border-bottom-right-radius:24px;
                box-sizing:border-box;
                overflow:hidden;

                &:before {
                    @include aspect-ratio('9 / 16'); // Aspect ratio: 16:9
                }

                svg {
                    @include animate;
                    @include absolute;
                    flex-shrink:0;
                    display:block;
                    margin-top:-6px;
                    fill:#FFF;
                    opacity:0;

                    @media screen and (min-width:769px) {
                        top:50%; left:50%;
                        width:32px; height:32px;
                        transform:translate(-50%, -50%);
                    }
                    @media screen and (max-width:768px) {
                        top:18px; left:18px;
                        width:24px; height:24px;
                    }
                }

                @media screen and (min-width:769px) {
                    &:not(.disabled):not(:disabled):hover svg,
                    &:not(.disabled):not(:disabled):focus svg {
                        opacity:1;
                    }
                }
                @media screen and (max-width:768px) {
                    margin-top:6px; padding:16px 18px;
                    font-size:14px;
                    text-align:left;
                }

                &.is-light {
                    color:map-get($colors, _01-01);

                    svg {
                        fill:map-get($colors, _01-01);
                    }
                }

                &.is-active {
                    cursor:default;

                    svg {
                        opacity:1;
                    }
                }

                &.disabled,
                &:disabled {
                    cursor:default;
                }
            }
                .product-range-item-picture {
                    @include full-size(-1);
                }
                    .product-range-item-img {
                        @include full-size;
                        height:100% !important;
                        object-fit:cover; font-family:'object-fit:cover;';
                    }
                .product-range-item-desc {
                    display:none;
                    opacity:0;
                    visibility:hidden;
                }

    .product-info {
        margin-top:96px;

        @media screen and (max-width:1220px) {
            margin-top:48px;
        }
    }

        .product-info-content {
            padding:48px;
            background:map-get($colors, _02-01);
            border-bottom-right-radius:24px;

            @media screen and (max-width:768px) {
                padding:24px;
            }

            &:not(.is-visible) {
                display:none;
            }
        }

        .product-info-rhc {

            @media screen and (max-width:768px) {
                padding-top:48px;
            }

            h2 {
                width:100%; max-width:map-get($widths, _xsmall);
                color:map-get($colors, _02-01);

                @media screen and (max-width:768px) {
                    line-height:32px;
                    font-size:24px;
                }
            }
            .btns-wrapper {
                padding-top:48px;

                @media screen and (max-width:1024px) and (min-width:769px) {
                    flex-direction:column;
                    text-align:center;

                    .btn:not(:last-child) {
                        margin-right:0; margin-bottom:6px;
                    }
                }
                @media screen and (max-width:768px) {
                    padding-top:18px;
                }
            }
        }
