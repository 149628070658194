/* ------------------------------------------------------------------------------------------------------------------------ */
/* BUTTON ----------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.btns-wrapper {

    &:not(:first-child) {
        padding-top:32px;

        @media screen and (max-width:768px) {
            padding-top:24px;
        }
    }

    &.direction-column {
        @include flex-column;

        .btn:not(:last-child) {
            margin-bottom:6px;
        }
    }

    &.direction-row {
        @include flex-row;

        @media screen and (max-width:768px) {
            flex-direction:column;
            align-items:flex-start;
        }

        &.align-center {

            @media screen and (min-width:769px) {
                justify-content:center;
            }
            @media screen and (max-width:768px) {
                align-items:center;
            }
        }
        &.align-right {

            @media screen and (min-width:769px) {
                justify-content:flex-end;
            }
            @media screen and (max-width:768px) {
                align-items:flex-end;
            }
        }

        .btn:not(:last-child) {

            @media screen and (min-width:769px) {
                margin-right:6px;
            }
            @media screen and (max-width:768px) {
                margin-bottom:6px;
            }
        }
    }
}

    .btn {
        @include animate;
        display:inline-block;
        font-weight:700;
        box-sizing:border-box;

        &.disabled,
        &:disabled {
            cursor:default;
        }
    }


/*
 * Btn (01, 02)
 */

.btn-01,
.btn-02 {
    padding:16px 28px;
    min-width:128px;
    line-height:map-get($line_heights, _small);
    font-size:map-get($font_sizes, _small);
    font-weight:700;

    @media screen and (max-width:768px) {
        padding:12px 24px;
        font-size:16px;
    }

    &.btn-01 {
        border-top-right-radius:24px;
    }

    &.btn-02 {
        border-radius:999px;
    }

    // White
    &.btn_style_white {
        @include btn_01(
            #FFF, #FFF,
            map-get($colors, _01-01), map-get($colors, _04-01),
            map-get($colors, _01-01), map-get($colors, _04-01)
        );
    }

    // Black
    &.btn_style_01-01 {
        @include btn_01(
            map-get($colors, _01-01), map-get($colors, _01-02),
            #FFF, #FFF,
            #FFF, #FFF
        );
    }

    // Gray
    &.btn_style_01-02 {
        @include btn_01(
            map-get($colors, _01-02), map-get($colors, _01-01),
            #FFF, #FFF,
            #FFF, #FFF
        );
    }

    // Light Gray
    &.btn_style_01-03 {
        @include btn_01(
            map-get($colors, _01-03), map-get($colors, _01-02),
            map-get($colors, _01-01), #FFF,
            map-get($colors, _01-01), #FFF
        );
    }

    // Petrol Blue
    &.btn_style_02-01 {
        @include btn_01(
            map-get($colors, _02-01), map-get($colors, _02-02),
            #FFF, #FFF,
            #FFF, #FFF
        );
    }

    // Teal Blue
    &.btn_style_02-02 {
        @include btn_01(
            map-get($colors, _02-02), map-get($colors, _02-01),
            #FFF, #FFF,
            #FFF, #FFF
        );
    }

    // Emerald
    &.btn_style_03-01 {
        @include btn_01(
            map-get($colors, _03-01), map-get($colors, _02-01),
            #FFF, #FFF,
            #FFF, #FFF
        );
    }

    // Emerald Alt
    &.btn_style_03-01_alt {
        @include btn_01(
            map-get($colors, _03-01), map-get($colors, _05-01),
            #FFF, #FFF,
            #FFF, #FFF
        );
    }

    // Purple
    &.btn_style_04-01 {
        @include btn_01(
            map-get($colors, _04-01), map-get($colors, _04-02),
            #FFF, #FFF,
            #FFF, #FFF
        );
    }

    // Dark Magenta
    &.btn_style_04-02 {
        @include btn_01(
            map-get($colors, _04-02), map-get($colors, _04-03),
            #FFF, #FFF,
            #FFF, #FFF
        );
    }

    // Magenta
    &.btn_style_04-03 {
        @include btn_01(
            map-get($colors, _04-03), map-get($colors, _04-02),
            #FFF, #FFF,
            #FFF, #FFF
        );
    }

    // Orange
    &.btn_style_05-01 {
        @include btn_01(
            map-get($colors, _05-01), map-get($colors, _04-03),
            #FFF, #FFF,
            #FFF, #FFF
        );
    }

    // Yellow
    &.btn_style_06-01 {
        @include btn_01(
            map-get($colors, _06-01), map-get($colors, _05-01),
            map-get($colors, _01-01), map-get($colors, _white),
            map-get($colors, _01-01), map-get($colors, _white)
        );
    }

    &.has_ajax-loader.is-loading {
        padding-right:48px;
    }
}


/*
 * Btn (Has Icon)
 */

.btn_has-icon {
    @include animate;
    display:flex;
    flex-direction:row;
    flex-wrap:nowrap;
    align-items:center;

    svg {
        @include animate;
        display:block;
        flex-shrink:0;
        width:16px; height:16px;
    }

    .btn-icon {
        flex-shrink:0;
    }
    .btn-label {

        @media screen and (max-width:768px) {
            flex:1 1 auto;
        }
    }

    // Icon (Left)
    &:not(.icon-right) .btn-icon {
        margin-right:12px;

        @media screen and (max-width:768px) {
            margin-right:9px;
        }
    }

    // Icon (Right)
    &.icon-right .btn-icon {
        order:2;
        margin-left:12px;

        @media screen and (max-width:768px) {
            margin-left:9px;
        }
    }

    // Icon (Right-End)
    &.icon-right-end {
        justify-content:space-between;
    }

    // Icon (Rotate -90deg)
    &.icon-minus-90-deg svg {
        transform:rotate(-90deg);
    }

    // Icon (Rotate 180deg)
    &.icon-180-deg svg {
        transform:rotate(180deg);
    }
}


/* ------------------------------------------------------------------------------------------------------------------------ */
/* BUTTON (PREV / NEXT) --------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.btn-prev,
.btn-next {
    @include animate;
    @include flex-column;
    @include absolute(2);
    justify-content:center;
    align-items:center;
    top:50%;
    width:48px; height:48px;
    fill:map-get($colors, _01-01);
    transform:translateY(-50%);

    svg {
        @include animate;
        width:24px; height:24px;

        @media screen and (max-width:768px) {
            width:16px !important; height:16px !important;
        }
    }

    &.swiper-button-disabled {
        cursor:default;
        opacity:0;
        visibility:hidden;
    }

    @media screen and (min-width:769px) {
        &:not(.swiper-button-disabled):hover,
        &:not(.swiper-button-disabled):focus {
            fill:map-get($colors, _01-01);
        }
    }
}
.btn-prev {
    left:48px;

    @media screen and (max-width:768px) {
        left:0;
    }

    svg {
        transform:rotate(90deg);
    }
}
.btn-next {
    right:48px;

    @media screen and (max-width:768px) {
        right:0;
    }

    svg {
        transform:rotate(-90deg);
    }
}


/* ------------------------------------------------------------------------------------------------------------------------ */
/* BUTTON (MODAL CLOSE) --------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.modal_btn-close {
    position:absolute;
    top:48px; right:48px;
    z-index:9;
    width:32px; height:32px;

    @media screen and (max-width:768px) {
        top:24px; right:24px;
        z-index:9;
        width:18px; height:18px;
    }

    svg {
        @include animate;
        display:block;
        width:32px; height:32px;
        fill:map_get($colors, _01-01);

        @media screen and (max-width:768px) {
            width:18px; height:18px;
        }
    }

    @media screen and (min-width:769px) {
        &:hover svg,
        &:focus svg {
            fill:map_get($colors, _04-01);
            transform:rotate(180deg);
        }
    }
}


/* ------------------------------------------------------------------------------------------------------------------------ */
/* LOADER ----------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.has_ajax-loader {
    @include relative;

    &:after {
        @include animate;
        @include absolute;
        content:"";
        top:50%; right:18px;
        z-index:1;
        width:18px; height:18px;
        margin-top:-9px;
        border:2px solid #FFF; border-top:2px solid transparent; border-radius:50%;
        box-sizing:border-box;
        opacity:0;
        visibility:hidden;
        animation:spin 600ms linear infinite;
    }

    &.is-loading {

        &:after {
            opacity:1;
            visibility:visible;
        }
    }

    &.ajax-loader_full {
        @include relative;

        &:before {
            @include animate;
            @include full-size(3);
            content:"";
            background:rgba(#FFF, .88);
            opacity:0;
            visibility:hidden;
        }
        &:after {
            left:50%; right:auto;
            z-index:3;
            width:48px; height:48px;
            margin-top:-24px; margin-left:-24px;
            border-color:map-get($colors, _01-01); border-top-color:transparent;
        }

        &.is-loading:before {
            opacity:1;
            visibility:visible;
        }
    }
}
