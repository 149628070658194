/* ------------------------------------------------------------------------------------------------------------------------ */
/* PRIMARY NAVIGATION ----------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.nav-primary {
    padding: 24px 48px 0;
    width: 100%;
    box-sizing: border-box;

    @media screen and (min-width: 769px) {
        @include flex-column;
        align-items: center;
    }
    @media screen and (max-width: 768px) {
        padding: 24px 24px 0;
    }
}

.nav-primary-list {
    line-height: map-get($line_heights, _small);
    list-style: none;

    @media screen and (min-width: 769px) {
        @include flex-row;
    }
}
.nav-primary-item {
    @media screen and (min-width: 1346px) {
        &.fix-nav:first-child,
        &.fix-nav:first-child ~ .nav-primary-item {
            padding: 0 32px;
        }

        &:first-child:not(.fix-nav),
        &:first-child:not(.fix-nav) ~ .nav-primary-item {
            min-width: 250px;
        }
    }
    @media screen and (max-width: 1345px) and (min-width: 769px) {
        &.fix-nav:first-child,
        &.fix-nav:first-child ~ .nav-primary-item {
            padding: 0 24px;
        }

        &:first-child:not(.fix-nav),
        &:first-child:not(.fix-nav) ~ .nav-primary-item {
            padding: 0 12px;
            width: 100%;
            max-width: 180px;
        }
    }
    @media screen and (min-width: 769px) {
        @include flex-column;
        align-items: center;
        text-align: center;

        &.fix-nav {
            margin-left: -77px;
        }
    }
    @media screen and (max-width: 768px) {
        &.open .nav-primary-accordion-trigger:after {
            transform: rotate(0deg);
        }

        &:last-child .nav-primary-link {
            margin-bottom: 0;
        }

        &:nth-child(5n + 1) {
            .nav-primary-link {
                border-bottom-color: map-get($colors, _04-03);

                &:before {
                    background: map-get($colors, _04-03);
                }
                &.is-current {
                    color: map-get($colors, _04-03);
                }
            }

            .subnav-primary-link.is-current {
                color: map-get($colors, _04-03);
            }
        }
        &:nth-child(5n + 2) {
            .nav-primary-link {
                border-bottom-color: map-get($colors, _05-01);

                &:before {
                    background: map-get($colors, _05-01);
                }
                &.is-current {
                    color: map-get($colors, _05-01);
                }
            }

            .subnav-primary-link.is-current {
                color: map-get($colors, _05-01);
            }
        }
        &:nth-child(5n + 3) {
            .nav-primary-link {
                border-bottom-color: map-get($colors, _06-01);

                &:before {
                    background: map-get($colors, _06-01);
                }
                &.is-current {
                    color: map-get($colors, _06-01);
                }
            }

            .subnav-primary-link.is-current {
                color: map-get($colors, _06-01);
            }
        }
        &:nth-child(5n + 4) {
            .nav-primary-link {
                border-bottom-color: map-get($colors, _03-01);

                &:before {
                    background: map-get($colors, _03-01);
                }
                &.is-current {
                    color: map-get($colors, _03-01);
                }
            }

            .subnav-primary-link.is-current {
                color: map-get($colors, _03-01);
            }
        }
        &:nth-child(5n + 5) {
            .nav-primary-link {
                border-bottom-color: map-get($colors, _02-02);

                &:before {
                    background: map-get($colors, _02-02);
                }
                &.is-current {
                    color: map-get($colors, _02-02);
                }
            }

            .subnav-primary-link.is-current {
                color: map-get($colors, _02-02);
            }
        }
    }
}
.nav-primary-link {
    @include animate;
    flex: 1 1 auto;
    color: #fff;
    font-size: map-get($font_sizes, _small);
    font-weight: 600;

    @media screen and (min-width: 769px) {
        @include flex-column;
        justify-content: center;

        &:not(.is-current):hover,
        &:not(.is-current):focus {
            color: map-get($colors, _06-01);
        }
    }
    @media screen and (max-width: 768px) {
        @include flex-row;
        margin-bottom: 18px;
        padding: 0 48px 18px 12px;
        color: map-get($colors, _01-01);
        border-bottom: 4px solid #000;

        &:before {
            content: "";
            display: block;
            flex-shrink: 0;
            margin: 8px 12px 0 0;
            width: 10px;
            height: 10px;
            background: #000;
            border-radius: 100%;
        }
    }

    &.is-mouseenter,
    &.is-open:not(.is-inactive) {
        @media screen and (min-width: 769px) {
            @include relative;

            &:after {
                @include absolute;
                content: "";
                bottom: -24px;
                left: 50%;
                transform: translateX(-50%);
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 0 12px 12px 12px;
                border-color: transparent transparent map-get($colors, _01-03)
                    transparent;
            }
        }
    }

    &.is-current {
        color: map-get($colors, _06-01);
        cursor: default;
    }
}

/*
 * Submenu
 */

.subnav-primary {
    padding: 18px 24px;
    box-sizing: border-box;

    &:not(.is-open),
    &.is-hidden {
        display: none;
    }
}
.subnav-primary-list {
    line-height: 20px;
    list-style: none;

    @media screen and (min-width: 769px) {
        @include flex-row;
        justify-content: center;
    }
    @media screen and (max-width: 1100px) and (min-width: 930px) {
        line-height: 18px;
    }
    @media screen and (max-width: 929px) and (min-width: 769px) {
        line-height: 14px;
    }
    @media screen and (max-width: 768px) {
        padding: 0 19px 24px;
    }
}
.subnav-primary-item {
    &:not(:first-child) {
        @media screen and (min-width: 769px) {
            margin-left: 24px;
            padding-left: 24px;
            border-left: 1px solid map-get($colors, _01-01);
        }
        @media screen and (max-width: 1100px) and (min-width: 930px) {
            margin-left: 16px;
            padding-left: 16px;
        }
        @media screen and (max-width: 929px) and (min-width: 769px) {
            margin-left: 0;
            padding-left: 24px;
            border-left: none;
        }
        @media screen and (max-width: 768px) {
            padding-top: 6px;
        }
    }

    @media screen and (min-width: 769px) {
        @include flex-column;
        justify-content: center;
    }
    @media screen and (max-width: 768px) {
        @include flex-row;

        &:before {
            content: "-";
            display: block;
            padding-right: 8px;
        }
    }
}

.subnav-primary-link {
    @include animate;
    font-size: 16px;
    font-weight: 500;

    @media screen and (max-width: 1100px) and (min-width: 930px) {
        font-size: 14px;
    }
    @media screen and (max-width: 929px) and (min-width: 769px) {
        font-size: 12px;
    }
    @media screen and (min-width: 769px) {
        text-align: center;
    }

    &:not(.subnav-primary-cta) {
        color: map-get($colors, _01-01);

        @media screen and (min-width: 769px) {
            &:not(.is-current):hover,
            &:not(.is-current):focus {
                color: map-get($colors, _04-03);
            }
        }

        &.is-current {
            color: map-get($colors, _04-03);
            cursor: default;
        }
    }
}
html {
    &[lang="de-DE"] {
        .subnav-primary-link {
            font-size: 16px;
            @media screen and (max-width: 1300px) and (min-width: 1199px) {
                font-size: 14px;
            }
            @media screen and (max-width: 1200px) {
                font-size: 12px;
            }
        }
    }
}
.subnav-primary-cta {
    white-space: nowrap;
    &--de {
        white-space: normal;
    }

    @media screen and (min-width: 769px) {
        display: inline;
        padding: 12px 24px;
        color: #fff;
        background: map-get($colors, _04-03);
        border-radius: 999px;
        border-top-left-radius: 0;

        &:not(.is-current):hover,
        &:not(.is-current):focus {
            background: map-get($colors, _04-02);
        }
    }
    @media screen and (max-width: 768px) {
        color: map-get($colors, _04-03);
        font-weight: 700;
    }

    &.is-current {
        cursor: default;
    }
}

/*
 * Trigger
 */

.nav-primary-trigger {
    display: block;
    margin-left: 24px;
    width: 24px;
    height: 24px;

    svg {
        @include animate;
        display: block;
        width: 24px;
        height: 24px;
        fill: map-get($colors, _01-02);
    }
}

/*
 * Mobile
 */

.nav-mobile {
    & > .js-modal_content {
        margin: 144px 0 61px;
    }
}

.nav-mobile-header {
    @include flex-row;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
    padding: 18px 24px;
    background: $bc_gradient;

    .header-contacts a svg,
    .nav-primary-trigger svg {
        fill: #fff;
    }

    .header-logo svg .logo-mark {
        fill: map-get($colors, _01-03);
    }
}

.nav-mobile-heading {
    position: fixed;
    top: 94px;
    left: 0;
    right: 0;
    z-index: 3;
    padding: 12px 24px;
    color: #fff;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    background: map-get($colors, _02-01);
    border-top: 1px solid rgba(#fff, 0.12);
}

.nav-primary-accordion-heading {
    @include relative;
}
.nav-primary-accordion-trigger {
    @include absolute;
    top: 50%;
    right: 0;
    margin-top: -12px;
    padding: 12px;
    width: 12px;
    height: 12px;
    box-sizing: content-box;
    transform: translateY(-50%);

    &:before,
    &:after {
        @include absolute;
        content: "";
        top: 17px;
        left: 12px;
        width: 12px;
        height: 2px;
        background: map-get($colors, _01-01);
    }
    &:after {
        @include animate;
        transform: rotate(90deg);
    }
}

.nav-mobile-contacts {
    padding: 24px;

    .btn {
        display: block;
        font-size: 16px;
        text-align: center;
        border-radius: 0;

        &:not(:first-child) {
            margin-top: 6px;
        }
    }
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* FOOTER NAVIGATION ------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */

.nav-footer.g-grid {
    @media screen and (max-width: 1220px) {
        flex-direction: column;
        margin: -24px 0 0;
    }
}
.nav-footer-list.g-column {
    line-height: map-get($line_heights, _small);
    list-style: none;

    @media screen and (max-width: 1220px) {
        padding: 24px 0 0 !important;
        width: 100%;
    }
}
.nav-footer-item {
    &:not(:first-child) {
        @media screen and (min-width: 1221px) {
            padding-top: 18px;
        }
    }
}
.nav-footer-link {
    @include animate;
    color: #fff;
    font-size: 16px;

    @media screen and (min-width: 1221px) {
        &:not(.is-current):hover,
        &:not(.is-current):focus {
            color: map-get($colors, _06-01);
        }
    }
    @media screen and (max-width: 1220px) {
        font-size: 14px;
    }
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* BREADCRUMBS ------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */

.breadcrumbs {
    @include flex-row;
    align-items: center;
}

.breadcrumbs-list {
    @include flex-row;
    flex: 1 1 auto;
    align-items: center;
    list-style: none;

    &:not(:only-child) {
        padding-right: 48px;
    }
}
.breadcrumbs-item {
    @include flex-row;
    align-items: center;

    &:not(:last-child) {
        padding-right: 9px;
    }

    svg {
        display: block;
        flex-shrink: 0;
        margin-top: 2px;
        width: 12px;
        height: 12px;
        fill: map-get($colors, _01-01);
    }
}
.breadcrumbs-link {
    @include animate;
    color: map-get($colors, _01-01);

    &:not(:only-child) {
        padding-right: 9px;
    }

    @media screen and (min-width: 769px) {
        &:not(.is-current):hover,
        &:not(.is-current):focus {
            color: map-get($colors, _04-02);
        }
    }

    &.is-current {
        cursor: default;
    }
}

.breadcrumbs-share {
    &:only-child {
        margin-left: auto;
    }
}
.breadcrumbs-share-btn {
    @include animate;
    color: map-get($colors, _01-01);
    font-size: map-get($font_sizes, _small);
    text-transform: uppercase;

    @media screen and (min-width: 769px) {
        &:not(.disabled):not(:disabled):hover,
        &:not(.disabled):not(:disabled):focus {
            color: map-get($colors, _04-02);
        }
    }
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* SWIPER PAGINATION ------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */

.slider-pagination {
    @include flex-row;
    justify-content: center;
    padding-top: 48px;
    width: 100%;

    @media screen and (max-width: 768px) {
        align-items: center;
    }

    .swiper-pagination-bullet {
        @include flex-column;
        justify-content: center;
        align-items: center;
        margin: 0 12px;
        width: 24px;
        height: 24px;
        background: #fff;
        border-radius: 100%;
        opacity: 1;

        @media screen and (max-width: 768px) {
            width: 12px;
            height: 12px;
        }

        &:before {
            @include animate;
            content: "";
            width: 12px;
            height: 12px;
            background: map-get($colors, _04-03);
            border-radius: 100%;
            opacity: 0;

            @media screen and (max-width: 768px) {
                width: 8px;
                height: 8px;
            }
        }

        &.swiper-pagination-bullet-active:before {
            opacity: 1;
        }
    }
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* SWIPER SLIDER PREV/NEXT ------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */

.slider-prev,
.slider-next {
    @include animate;
    display: block;
    width: 48px;
    height: 48px;
    fill: #fff;

    @media screen and (max-width: 768px) {
        width: 24px;
        height: 24px;
    }

    svg {
        @include animate;
        display: block;
        width: inherit;
        height: inherit;
    }

    @media screen and (min-width: 769px) {
        &:not(:disabled):hover,
        &:not(:disabled):focus {
            fill: #fff;
        }
    }

    &:disabled {
        opacity: 0.12;
        cursor: default;
    }
}

.slider-prev {
    margin-right: 48px;

    svg {
        transform: rotate(180deg);
    }

    @media screen and (max-width: 768px) {
        margin-right: 24px;
    }
}

.slider-next {
    margin-left: 48px;

    @media screen and (max-width: 768px) {
        margin-left: 24px;
    }
}
