/* ------------------------------------------------------------------------------------------------------------------------ */
/* SPLIT ------------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */

.pb-block-split {
    padding:0;

    @media screen and (min-width:769px) {
        @include flex-row;
    }
}
    .pb-block-split-column {
        @include flex-column;

        @media screen and (min-width:769px) {
            flex-shrink:0;
            width:50%;
        }
    }


/*
 * Intro
 */

.pb-block-split-column-intro {
    padding:48px 96px;

    @media screen and (max-width:1220px) and (min-width:769px) {
        padding:48px;
    }
    @media screen and (max-width:768px) {
        padding:48px 24px;
    }
}
    .pb-block-split-column-heading {
        line-height:37px;
        font-size:30px;
    }


/*
 * Media
 */

.pb-block-split-column-media {
    @include flex-row;
    @include relative;
    margin-top:auto;

    &:before {
        @include aspect-ratio('9 / 16'); // Aspect ratio: 16:9
    }

    .wrapper-video,
    .media-picture {
        @include full-size;
    }
        .wrapper-video iframe,
        .media-img {
            height:100% !important;
            object-fit:contain; object-position:center bottom; font-family:'object-fit:contain; object-position:center bottom;';
        }
}


/*
 * Bottom
 */

.pb-block-split-column-bottom {
    @include relative;
    background:inherit;

    &:before {
        @include animate;
        @include absolute(-1);
        content:"";
        top:-48px; left:0; right:0;
        height:48px;
        background:linear-gradient(to top, rgba(#000, .24) 0%, rgba(#000, 0) 100%);
    }
}

    .pb-block-split-column-logos {
        @include flex-row;
        flex-wrap:wrap;
        justify-content:center;
        padding:0 48px 24px;
        background:#FFF;
    }
        .pb-block-split-column-logo {
            @include flex-row;
            @include relative;
            flex:1 1 auto;
            justify-content:center;
            align-items:center;
            margin:24px 12px 0;
            min-width:96px; max-width:128px;

            &:before {
                @include aspect-ratio('9 / 16'); // Aspect ratio: 16:9
            }
        }
            .pb-block-split-column-logo-link {
                @include full-size(2);
            }
            .pb-block-split-column-logo-picture {
                @include full-size;
            }
                .pb-block-split-column-logo-img {
                    height:100% !important;
                    object-fit:contain; font-family:'object-fit:contain;';
                }

    .pb-block-split-column-cta-wrapper {
        @include flex-row;
        justify-content:center;
        padding:24px 0;

        .pb-block-split-column-cta {
            color:inherit !important;
            text-transform:uppercase;

            @media screen and (min-width:769px) {
                &:not(.disabled):not(:disabled):hover,
                &:not(.disabled):not(:disabled):focus {
                    color:inherit !important;
                }
            }
        }

        &.color_06-01 .pb-block-split-column-cta {
            color:map-get($colors, _01-01) !important;

            @media screen and (min-width:769px) {
                &:not(.disabled):not(:disabled):hover,
                &:not(.disabled):not(:disabled):focus {
                    color:map-get($colors, _01-01) !important;
                }
            }
        }
    }
