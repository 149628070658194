/* ------------------------------------------------------------------------------------------------------------------------ */
/* GOOGLE MAP ------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.gmap-wrapper {
    @include relative;
    @include flex-row;
    background:#E5E3DF;
    overflow:hidden;
    overflow-anchor:none;

    &:before {
        @include aspect-ratio('9 / 16'); // Aspect ratio: 16:9
    }
}
    .gmap {
        @include full-size;
    }
