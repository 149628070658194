/* ------------------------------------------------------------------------------------------------------------------------ */
/* FASTLINKS -------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.pb-block-fastlinks {
}


/*
 * Heading
 */

.pb-block-fastlinks-wrapper {

    @media screen and (min-width:769px) {
        border:2px solid #FFF;
        border-top-right-radius:48px;
    }
}
    .pb-block-fastlinks-heading {
        color:#FFF;
        line-height:38px;
        font-size:30px;
        font-weight:700;
        text-align:center;
        box-sizing:border-box;

        @media screen and (min-width:769px) {
            margin:0 auto;
            padding:0 24px;
            min-width:312px;
        }
    }


/*
 * Items
 */

.pb-block-fastlinks-list {

    @media screen and (min-width:769px) {
        margin:-48px auto 0; padding:48px;
        width:calc(100% - 96px); max-width:map-get($widths, _medium_large);
    }
    @media screen and (max-width:1220px) and (min-width:769px) {

        & > .g-grid > .g-column {
            width:calc((6 / 12) * 100%);
        }
    }
    @media screen and (max-width:768px) {
        margin:-24px auto 0; padding-top:24px;
    }
}
    .pb-block-fastlink {
        @include flex-column;
        flex:1 1 auto;
        margin:48px 0 24px; padding:12px;
        background:#FFF;
        border-bottom-left-radius:24px;

        @media screen and (max-width:768px) {
            margin-top:24px;
        }
    }
        .pb-block-fastlink-thumb {
            display:block;
        }
        .pb-block-fastlink-heading {
            padding:24px 20px 0;
            line-height:32px;
            font-size:24px;
            text-align:center;
            text-transform:uppercase;
        }
        .pb-block-fastlink-text {
            padding:12px 20px 0;
        }
        .pb-block-fastlink-cta-wrapper {
            @include flex-column;
            align-items:flex-end;
            margin-top:auto; padding-top:32px;
        }
            .pb-block-fastlink-cta {
                margin-right:-36px; margin-bottom:-36px;
                box-shadow:0 0 12px rgba(#000, .24);

                @media screen and (max-width:768px) {
                    margin-right:-24px;
                }
            }

/*
 * Background
 */

.pb-block-fastlinks-bg {
    background:#000;
}
