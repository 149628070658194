/* ------------------------------------------------------------------------------------------------------------------------ */
/* DOWNLOADS -------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.pb-block-downloads {
    border-top:16px solid map-get($colors, _06-01);
    border-bottom:16px solid map-get($colors, _04-03);

    @media screen and (max-width:1024px) and (min-width:769px) {

        & >.pb-block_content > .g-grid > .g-column {
            width:100%;
        }
    }
}
    .pb-block-downloads-lhc {
        justify-content:center;

        @media screen and (max-width:1024px) and (min-width:769px) {
            padding-bottom:24px;
        }
    }
        .pb-block-downloads-heading {
            line-height:40px;
            color:map-get($colors, _02-01);
            font-size:32px;
            font-weight:600;
        }
        .pb-block-downloads-text {
            padding-top:24px;

            @media screen and (max-width:1024px) {
                padding-top:12px;
            }
        }
    .pb-block-downloads-rhc {

        @media screen and (max-width:768px) {
            padding-top:24px;
        }
    }


/*
 * Downloads
 */

.pb-block-download {
    @include flex-row;
    @include relative;
    align-items:center;
    padding:18px 48px 18px 24px;
    background:#FFF;
    border:1px solid map-get($colors, _04-02);
    border-bottom-right-radius:24px;

    &:not(:first-child) {
        margin-top:24px;

        @media screen and (max-width:1024px) {
            margin-top:12px;
        }
    }

    @media screen and (min-width:769px) {
        &:hover,
        &:focus {

            .pb-block-download-icon svg {
                fill:map-get($colors, _04-02);
            }
            .pb-block-download-icon-label,
            .pb-block-download-heading {
                color:map-get($colors, _04-02);
            }
        }
    }
}

    .pb-block-download-link {
        @include full-size(2);
    }

    .pb-block-download-icon {
        @include relative;
        flex-shrink:0;
        margin-right:24px;
        width:48px; height:48px;

        svg {
            @include animate;
            display:block;
            width:inherit; height:inherit;
            fill:map-get($colors, _01-01);
        }
    }
        .pb-block-download-icon-label {
            @include animate;
            @include absolute;
            top:25px; left:0; right:0;
            line-height:1;
            color:map-get($colors, _01-01);
            font-size:12px;
            font-weight:700;
            text-align:center;
            text-transform:uppercase;
        }

    .pb-block-download-heading {
        @include animate;
        flex:1 1 auto;
        line-height:map-get($line_heights, _small);
        color:map-get($colors, _01-01);
        font-size:map-get($font_sizes, _small);
        font-weight:700;
        text-transform:uppercase;
    }

    .pb-block-download-triangle {
        flex-shrink:0;
        margin-left:24px;
        width:22px; height:18px;

        svg {
            display:block;
            width:inherit; height:inherit;
            fill:map-get($colors, _04-02);
        }
    }


/*
 * Background
 */

.pb-block-downloads-bg {
    @include full-size(-1);
    overflow:hidden;
}
    .pb-block-downloads-bg-picture {
        @include absolute;
        @include flex-row;
        top:0; left:45%; right:0; bottom:0;

        &:before {
            @include aspect-ratio('1280 / 1920'); // Aspect ratio: 4:3
        }
    }
        .pb-block-downloads-bg-img {
            @include full-size;
            position:absolute !important;
            height:100% !important;
            object-fit:contain; font-family:'object-fit:contain;';
            pointer-events:none !important;
        }
