/* ------------------------------------------------------------------------------------------------------------------------ */
/* HEADER ----------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.header {
    @include animate;
    position:fixed;
    top:0; left:0; right:0;
    z-index:99;
    background:map-get($colors, _01-03);

    @media screen and (max-width:768px) {
        background:#FFF;
    }

    &:after {
        @include animate;
        @include absolute(-1);
        content:"";
        bottom:-6px; left:0; right:0;
        height:6px;
        background:linear-gradient(to bottom, rgba(#000, .12) 0%, rgba(#000, 0) 100%);

        @media screen and (min-width:769px) {
            opacity:0;
        }
    }
}


/*
 * Header Lang
 */

.header-lang {
    @include animate;
    @include flex-column;
    @include relative(2);
    align-items:center;
    padding:6px 24px;
    color:#FFF;
    background:map-get($colors, _01-01);

    @media screen and (min-width:769px) {
        border-bottom:4px solid map-get($colors, _06-01);
    }
    @media screen and (max-width:768px) {
        padding:12px;
    }

    &.hide {
        margin-top:-62px;

        @media screen and (max-width:768px) {
            margin-top:-56px;
        }
    }

    .nav-lang {
        @include flex-row;
        align-items:center;
        width:100%;
        line-height:map-get($line_heights, _small);
        font-size:16px;

        @media screen and (min-width:769px) {
            padding:6px;
            max-width:400px;
            border:1px solid #FFF;
            border-radius:999px;
        }
    }
        .nav-lang-trigger {

            @media screen and (min-width:769px) {
                flex:1 1 auto;
                margin-left:-92px; padding:0 24px;
            }
        }
        .nav-lang-list {
            bottom:auto;
            width:auto;
            text-align:center;
            border-radius:6px;

            @media screen and (min-width:769px) {
                top:53px;
                min-width:120px;
            }
            @media screen and (max-width:768px) {
                top:50px; left:-6px; right:-6px;
                padding:16px 24px;
                box-sizing:border-box;
                transform:none;
            }

            &:before {
                top:-16px; bottom:auto;
                height:16px;
            }
            &:after {
                display:none;
            }
        }
}
    .header-lang-close {
        @include absolute;
        top:50%; right:24px;
        width:16px; height:16px;
        transform:translateY(-50%);

        @media screen and (max-width:768px) {
            right:18px;
        }

        svg {
            @include animate;
            display:block;
            width:16px; height:16px;
            fill:#FFF;
        }

        @media screen and (min-width:769px) {
            &:not(:disabled):hover svg,
            &:not(:disabled):focus svg {
                transform:rotate(180deg);
            }
        }
    }


/*
 * Header Contacts
 */

.header-contacts {

    @media screen and (min-width:769px) {
        @include absolute;
        top:12px; right:0;
        padding:16px 18px 14px;
        line-height:20px;
        font-size:16px;
        font-weight:700;
        text-align:right;
        background:map-get($colors, _06-01);
        border-bottom-left-radius:24px;
    }
    @media screen and (max-width:768px) {
        order:-1;
        padding-right:24px;
    }

    a {
        @include animate;
        display:block;
        color:map-get($colors, _02-01);

        @media screen and (min-width:769px) {
            margin-left:auto;
            width:fit-content;

            &:hover,
            &:focus {
                color:map-get($colors, _04-03);
            }
        }
        @media screen and (max-width:768px) {

            &:not(:first-child) {
                margin-top:9px;
            }
        }

        svg {

            @media screen and (max-width:768px) {
                display:block;
                width:24px; height:24px;
                fill:map-get($colors, _01-02);
            }
        }
    }
}


/*
 * Header Top
 */

.header-top {
    @include flex-column;
    @include relative;
    align-items:center;
    padding:24px 0;
    overflow:hidden;

    @media screen and (min-width:769px) {
        background:$bc_gradient;
        border-bottom-left-radius:24px;
    }
    @media screen and (max-width:768px) {
        flex-direction:row;
        justify-content:space-between;
        padding:18px 24px;
    }
}

    .header-logo,
    .header-logo svg {
        display:block;
        width:auto;
        height:86px;

        @media screen and (max-width:768px) {
            height:58px;

            .logo-mark {
                fill:map-get($colors, _01-02);
            }
        }
    }

/*
 * Header Bottom
 */

.header-bottom {
    @include flex-column;
    justify-content:center;
}

/*
 * Sticky Header
 */

body.is-sticky-header {

    &.sticky-down .header {
        transform:translateY(-100%);
    }

    &.sticky-up .header:after {

        @media screen and (min-width:769px) {
            opacity:1;
        }
    }
}
