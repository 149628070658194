/* ------------------------------------------------------------------------------------------------------------------------ */
/* ARCHIVE ---------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.archive-grid {
    margin-top:-48px;

    @media screen and (max-width:1140px) and (min-width:769px) {

        &.g-grid {
            margin-left:-12px; margin-right:-12px;

            & > .g-column {
                padding-left:12px; padding-right:12px;
                width:calc((6 / 12) * 100%);
            }
        }
    }
    @media screen and (max-width:1140px) {
        margin-top:-24px;
    }
}

.page-builder.pb-archive,
.wp-block-acf-hot-topics {

    .archive-grid {
        padding-top:48px;

        @media screen and (max-width:1140px) {
            padding-top:24px;
        }
    }
}
