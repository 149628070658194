/* ------------------------------------------------------------------------------------------------------------------------ */
/* TESTIMONIAL ------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */

.testimonial-wrapper {
    color:#FFF;
}
    .testimonial-logo svg {
        display:block;
        width:auto; height:32px;
        fill:#FFF;
    }

    .testimonial {
        @include flex-row;
        align-items:center;

        &:not(:first-child) {
            padding-top:24px;
        }
    }
        .testimonial-text {

            &:not(:only-child) {
                padding-right:24px;
            }
        }
        .testimonial-picture {
            flex-shrink:0;
            width:128px; height:128px;
            border:2px solid #FFF;
            border-radius:12px;
            overflow:hidden;
        }
            .testimonial-img {
                margin:5px;
                width:calc(100% - 10px) !important;
                border-radius:10px;
            }
