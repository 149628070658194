/* ------------------------------------------------------------------------------------------------------------------------ */
/* HERO ------------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.pb-block-hero {

    @media screen and (min-width:769px) {
        @include flex-row;
        justify-content:center;
        padding-bottom:128px;
        min-height:280px;
    }
    @media screen and (max-width:1220px) and (min-width:769px) {
        padding-bottom:80px;
    }
    @media screen and (max-width:768px) {
        @include flex-column;
        padding:0;
    }

    &:not(.h-default):before {

        @media screen and (min-width:769px) {
            @include aspect-ratio('640 / 1920'); // Aspect ratio: 3:1
        }
    }

    // Tall Height
    &.h-tall .pb-block-hero-wrapper.bg-dark-mobile {

        @media screen and (max-width:768px) {
            background:map-get($colors, _02-01);
        }
    }

    // Default / Short Height
    &.h-default,
    &.h-short {
        // margin-bottom:48px;

        .pb-block-hero-wrapper {

            @media screen and (max-width:768px) {
                @include absolute(2);
                left:0; right:0; bottom:0;
                padding:24px;
            }
        }
    }
}


/*
 * Content
 */

.pb-block-hero-wrapper {
    @include flex-row;

    @media screen and (min-width:769px) {
        width:100%; max-width:map-get($widths, _base);
    }
    @media screen and (max-width:768px) {
        padding:48px 24px;
    }

    // Positions
    @media screen and (min-width:769px) {

        &.pos-top_right,
        &.pos-middle_right,
        &.pos-bottom_right {
            text-align:right;
        }
        &.pos-top_right {
            justify-content:flex-end;
        }
        &.pos-middle_left {
            align-items:center;
        }
        &.pos-middle_right {
            justify-content:flex-end;
            align-items:center;
        }
        &.pos-bottom_left {
            align-items:flex-end;
        }
        &.pos-bottom_right {
            justify-content:flex-end;
            align-items:flex-end;
        }
    }


    // Has border
    @media screen and (min-width:769px) {

        &.has-border {
            @include relative;
            margin-bottom:-128px; padding-bottom:128px;

            @media screen and (max-width:1220px) {
                margin-bottom:-80px; padding-bottom:80px;
            }

            &:before,
            .pb-block-hero-content:before,
            .pb-block-hero-content:after {
                @include absolute;
                content:"";
                background:currentColor;
            }

            &:before,
            .pb-block-hero-content:before {
                left:0; bottom:0;
                width:2px;
            }

            .pb-block-hero-content {
                @include relative;
                padding-left:48px;

                &:before {
                    top:0;
                    width:2px;
                }

                &:after {
                    top:-10px; left:-4px;
                    width:10px; height:10px;
                    border-radius:100%;
                }
            }

            &.pos-middle_left:before,
            &.pos-middle_right:before {
                height:calc(50% + 24px);
            }

            &.pos-top_left:before,
            &.pos-top_right:before,
            &.pos-bottom_left:before,
            &.pos-bottom_right:before {
                height:128px;

                @media screen and (max-width:1220px) {
                    height:80px;
                }
            }

            &.pos-top_right,
            &.pos-middle_right,
            &.pos-bottom_right {

                &:before,
                .pb-block-hero-content:before {
                    left:auto; right:0;
                }

                .pb-block-hero-content {
                    padding-left:0; padding-right:48px;

                    &:after {
                        left:auto; right:-4px;
                    }
                }
            }

            .pb-block-hero-heading {
                margin-top:-24px;
            }
        }
    }
}
    .pb-block-hero-content {
        width:100%; max-width:map-get($widths, _medium_small);
    }

        .pb-block-hero-heading {
            line-height:80px;
            font-size:80px;
            font-weight:700;

            @media screen and (max-width:768px) {
                line-height:44px;
                font-size:38px;
            }
        }

        .pb-block-hero-text {
            padding-top:48px;

            @media screen and (max-width:768px) {
                padding-top:24px;
            }
        }


/*
 * Background
 */

.pb-block-hero-bg {

    @media screen and (max-width:768px) {
        @include relative;
        order:-1;

        &:before {
            @include aspect-ratio('576 / 768'); // Aspect ratio: 4:3
        }
    }
}


/*
 * Mask
 */

.pb-block-hero-mask {
    @include flex-row;
    @include absolute;
    left:0; right:0; bottom:0;
    height:32px;
    color:#FFF;

    &:before,
    &:after {
        content:"";
        width:50%; height:0;
        border-bottom:32px solid currentColor;
    }
    &:before {
        border-right:32px solid transparent;
    }
    &:after {
        border-left:32px solid transparent;
    }
}
