/* ------------------------------------------------------------------------------------------------------------------------ */
/* GOOGLE MAP ------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.pb-block-google-map {

    .gmap-wrapper {
        flex:1 1 auto;
        width:100%;

        @media screen and (min-width:769px) {
            border-bottom-left-radius:48px;
        }
        @media screen and (max-width:768px) {
            margin-top:48px; padding-right:0 !important;
        }
    }
}
