/* ------------------------------------------------------------------------------------------------------------------------ */
/* HERO SLIDER ------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */

.pb-block-hero-slider {
    padding:0;
}

.hero-slider.add-animation {

    .swiper-slide-active .hero-slide-heading,
    .swiper-slide-active .hero-slide-text,
    .swiper-slide-active .hero-slide-cta-wrapper {
        transform:translateX(0);
        opacity:1;
    }

    .swiper-slide-active .hero-slide-bg-image,
    .swiper-slide-active .hero-slide-media .wrapper-video iframe,
    .swiper-slide-active .hero-slide-media .media-img {
        transform:scale(1);
    }
}


/*
 * Items
 */

.hero-slide {
    @include relative;

    @media screen and (min-width:769px) {
        @include flex-row;
        justify-content:center;

        &:before {

            @media screen and (min-width:769px) {
                @include aspect-ratio('1 / 2'); // Aspect ratio: 2:1
            }
            @media screen and (max-width:768px) {
                @include aspect-ratio('3 / 4'); // Aspect ratio: 4:3
            }
        }
    }

    &.slide-dark {
        background:#000;

        .hero-slide-media {

            @media screen and (max-width:768px) {
                background:rgba(#FFF, .06);
            }
        }
    }

    .pb-block_content {

        @media screen and (min-width:769px) {
            @include flex-column;
            justify-content:center;
            padding-bottom:104px;
        }
    }
}

    .hero-slide-lhc {

        @media screen and (min-width:769px) {
            width:50%; max-width:map-get($widths, _small);
            box-sizing:border-box;
        }
        @media screen and (max-width:768px) {
            padding:48px 24px;
        }
    }
        .hero-slide-heading,
        .hero-slide-text,
        .hero-slide-cta-wrapper {
            opacity:0;
            transform:translateX(-150px);
            transition-timing-function:ease-out;
            transition-duration:600ms;
        }
        .hero-slide-heading {
            line-height:80px;
            font-size:80px;
            font-weight:700;
            text-transform:uppercase;

            @media screen and (max-width:1220px) and (min-width:769px) {
                line-height:64px;
                font-size:64px;
            }
            @media screen and (max-width:768px) {
                line-height:48px;
                font-size:48px;
            }

            .size-medium {
                line-height:60px;
                font-size:60px;

                @media screen and (max-width:1220px) and (min-width:769px) {
                    line-height:50px;
                    font-size:50px;
                }
                @media screen and (max-width:768px) {
                    line-height:40px;
                    font-size:40px;
                }
            }

            .size-small {
                line-height:40px;
                font-size:40px;

                @media screen and (max-width:1220px) and (min-width:769px) {
                    line-height:30px;
                    font-size:30px;
                }
                @media screen and (max-width:768px) {
                    line-height:24px;
                    font-size:24px;
                }
            }
        }
        .hero-slide-text {
            padding-top:24px;
            transition-delay:150ms;
        }
        .hero-slide-cta-wrapper {
            @include flex-row;
            padding-top:48px;
            transition-delay:300ms;

            @media screen and (max-width:768px) {
                justify-content:center;
                padding:24px;
                min-height:49px;
            }
        }

    .hero-slide-media {
        @include flex-row;
        border-bottom-left-radius:48px;
        overflow:hidden;

        @media screen and (min-width:769px) {
            @include absolute;
            top:0; right:0;
            width:50%;
        }
        @media screen and (max-width:768px) {
            @include relative;
            width:100%;
            background:rgba(#000, .06);
        }

        &:before {
            @include aspect-ratio('960 / 1414');
        }

        .wrapper-video,
        .media-picture {
            @include full-size;
        }
            .wrapper-video iframe,
            .media-img {
                height:100% !important;
                object-fit:cover; font-family:'object-fit:cover;';
                transform:scale(1.2);
                transition-timing-function:ease-out;
                transition-duration:1200ms;
            }
    }

    .hero-slide-bg {
        @include full-size(-1);
        overflow:hidden;
    }
        .hero-slide-bg-picture,
        .hero-slide-bg-image {
            @include full-size;
        }
        .hero-slide-bg-image {
            @include animate;
            position:absolute !important;
            height:100% !important;
            object-fit:cover; font-family:'object-fit:cover;';
            transform:scale(1.2);
            transition-timing-function:ease-out;
            transition-duration:900ms;
        }


/*
 * Slider
 */

.hero-slider_prev,
.hero-slider_next {
    @include absolute(2);

    @media screen and (min-width:1221px) {
        top:50%;
        transform:translateY(-50%);
    }
    @media screen and (max-width:1220px) and (min-width:769px) {
        bottom:68px;
    }
    @media screen and (max-width:768px) {
        bottom:105px;
        width:100%; height:auto;

        &:before {
            @include aspect-ratio('960 / 1414');
        }
        &:after {
            @include absolute;
            content:"";
            top:50%;
            width:128px; height:128px;
            background:map-get($colors, _04-03);
            border-radius:100%;
            transform:translateY(-50%);
        }
    }

    svg {
        filter:drop-shadow(0 3px 6px rgba(#000, .48));

        @media screen and (max-width:768px) {
            @include absolute(2);
            top:50%;
            width:24px; height:24px;
        }
    }
}
.hero-slider_prev {
    left:48px;

    @media screen and (max-width:768px) {
        left:calc(-100vw + 64px);

        &:after {
            right:0;
        }

        svg {
            right:26px;
            transform:translateY(-50%) rotate(180deg);
        }
    }
}
.hero-slider_next {
    right:48px;

    @media screen and (max-width:768px) {
        right:calc(-100vw + 64px);

        &:after {
            left:0;
        }

        svg {
            left:26px;
            transform:translateY(-50%);
        }
    }
}

.hero-slider_pagination {
    @include absolute(2);
    left:50%; bottom:80px;
    transform:translateX(-50%);
}
