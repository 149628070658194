/* ------------------------------------------------------------------------------------------------------------------------ */
/* GALLERY ---------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.pb-block-gallery {
    background:map-get($colors, _02-01);
    overflow:hidden;
}


/*
 * Intro
 */

.pb-block-gallery-heading {
    line-height:39px;
    font-size:32px;
    font-weight:600;
}


/*
 * Grid
 */

.gallery {
    margin-top:-12px; padding-top:48px;

    @media screen and (max-width:768px) {
        @include flex-row;
        flex-wrap:wrap;
        justify-content:space-between;
        margin-top:-6px;
    }

    & > .g-column {
        padding-top:12px;

        @media screen and (max-width:1056px) and (min-width:769px) {
            width:calc((4 / 12) * 100%);
        }
        @media screen and (max-width:768px) {
            padding-top:6px;
            width:calc(50% - 3px);
        }
    }
}
    .gallery-item {
        @include relative;

        &:hover > .gallery-item-overlay,
        &:focus > .gallery-item-overlay {
            opacity:1;
            visibility:visible;
        }
    }
        .gallery-item-overlay {
            @include animate;
            @include flex-column;
            @include full-size(2);
            justify-content:flex-end;
            padding:32px;
            line-height:map-get($line_heights, _small);
            color:#FFF;
            background:rgba(#000, .88);
            box-sizing:border-box;
            opacity:0;
            visibility:hidden;

            @media screen and (max-width:768px) {
                padding:9px 12px;
                line-height:18px;
            }
        }
            .gallery-item-content {

                @media screen and (min-width:769px) {
                    padding-left:20px;
                    border-left:4px solid #FFF;
                }
            }
                .gallery-item-heading {
                    font-size:map-get($font_sizes, _base);
                    font-weight:600;

                    @media screen and (max-width:768px) {
                        font-size:10px;
                    }
                }
                .gallery-item-desc {
                    padding-top:6px;
                    font-weight:300;

                    @media screen and (max-width:768px) {
                        font-size:10px;
                    }
                }
                .gallery-item-link {
                    padding-top:12px;
                    width:fit-content;
                    font-size:18px;
                    font-weight:500;
                    text-align:center;
                    text-transform:uppercase;

                    @media screen and (max-width:768px) {
                        font-size:10px;
                    }

                    svg {
                        fill:#FFF !important;

                        @media screen and (max-width:768px) {
                            margin-top:-1px;
                            width:9px; height:9px;
                        }
                    }

                    @media screen and (min-width:769px) {
                        &:not(.disabled):not(:disabled):hover,
                        &:not(.disabled):not(:disabled):focus {
                            color:map-get($colors, _06-01);

                            svg {
                                fill:map-get($colors, _06-01) !important;
                            }
                        }
                    }
                }
