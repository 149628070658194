/* ------------------------------------------------------------------------------------------------------------------------ */
/* FIELD ------------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */

.field {
    padding-top:24px;

    @media screen and (max-width:768px)
    {
        padding-top:18px;
    }
}
    .field-heading,
    .field-label {
        @include flex-row;
        padding:0 0 6px 0;
        line-height:map-get($line_heights, _base);
        color:map-get($colors, _01-01);
        font-size:16px;

        @media screen and (max-width:768px)
        {
            align-items:flex-end;
        }

        em {
            margin-left:auto; padding-left:24px;
            color:map-get($colors, _01-02);
            font-weight:400;
            text-transform:none;
        }
    }


/*
 * Form Light Modifier
 */

.form-light {

    .field-label {
        color:#FFF;

        em {
            color:rgba(#FFF, .64);
        }
    }
}


/*
 * In-field
 */

.js-infield {
    @include relative(2);
}


/* ------------------------------------------------------------------------------------------------------------------------ */
/* FIELD FEEDBACK --------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.field-feedback {
    @include animate;
    @include relative;
    max-height:0;
    overflow:hidden;
    opacity:0;

    span {
        display:block;
        padding-top:12px;
        line-height:1.3;
        color:map-get($colors, _error);
        font-size:14px;

        @media screen and (max-width:768px)
        {
            margin-bottom:2px;
            line-height:1.2;
            font-size:12px;
        }
    }
}

.field-label .field-feedback {
    flex:1 1 auto;
    padding:0 18px 0 24px;
    text-align:right;

    span {
        padding-top:0;
    }
}


/*
 * Form Light Modifier
 */

.form-light {

    .field-feedback span {
        color:rgba(#FFF, .64);
    }
}


/* ------------------------------------------------------------------------------------------------------------------------ */
/* FIELD BADGES ----------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.badge-valid,
.badge-invalid {
    @include animate;
    @include flex-column;
    justify-content:center;
    align-items:center;
    position:absolute;
    right:-12px; top:-12px;
    z-index:2;
    width:24px; height:24px;
    border-radius:100%;
    opacity:0;

    svg {
        display:block;
        width:12px; height:12px;
        fill:#FFF;
    }
}
.badge-valid {
    background:map-get($colors, _02-01);
}
.badge-invalid {
    background:map-get($colors, _error);
}

.input-radio,
.input-checkbox {

    .badge-valid,
    .badge-invalid {
        top:0; left:26px; right:auto;
        margin-top:-2px;
        width:8px; height:8px;

        @media screen and (max-width:768px)
        {
            left:27px;
            margin-top:0;
        }

        svg {
            display:none;
        }
    }
}

/* --- Valid --- */
.is-valid {

    .field-feedback,
    .badge-invalid {
        opacity:0;
    }
    .badge-valid {
        opacity:1;
    }
}

/* --- Invalid --- */
.is-invalid {

    .badge-valid {
        opacity:0;
    }
    .field-feedback,
    .badge-invalid {
        opacity:1;
    }

    .field-feedback {
        max-height:none;
    }
}


/*
 * Form Light Modifier
 */

.form-light {
}


/* ------------------------------------------------------------------------------------------------------------------------ */
/* INPUT TEXT / TEXTAREA -------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.input-text,
.input-textarea {
    @include animate;
    @include relative;
    border:1px solid map-get($colors, _01-02);
    border-radius:6px;

    input,
    textarea {
        @include animate;
        margin:0; padding:16px 28px;
        width:100%; height:auto;
        line-height:map-get($line_heights, _base);
        color:map-get($colors, _01-01);
        font-family:map-get($font_families, _01);
        font-size:100%; font-size:16px;
        background:none;
        border:none;
        border-radius:6px;
        box-shadow:0 0 0 0 transparent inset !important;
        box-sizing:border-box;
        appearance:none;
    }
    input {

        &:not([value=""]) + .label_in-field,
        &:focus + .label_in-field {
            z-index:1;
            padding:1px 28px;
            font-size:8px;
        }

        @media screen and (min-width:769px)
        {
            &:not(:disabled):hover,
            &:not(:disabled):focus {
                position:relative;
                z-index:2;
            }
        }

        &:disabled {
            color:rgba(map-get($colors, _01-01), .24);
            cursor:default;
        }
    }

    .label_in-field {
        @include animate;
        @include full-size;
        padding:16px 28px;
        line-height:map-get($line_heights, _small);
        color:map-get($colors, _01-01);
        font-size:16px;
        box-sizing:border-box;
        cursor:pointer;
        pointer-events: none;
    }
}


/*
 * Form Light Modifier
 */

.form-light {

    .input-text {
        background:#FFF;
    }
}


/* ------------------------------------------------------------------------------------------------------------------------ */
/* SELECT ----------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.field-select {
    @include animate;
    @include relative;
    border:1px solid map-get($colors, _01-02);
    border-radius:6px;

    select {
        @include animate;
        margin:0; padding:16px 75px 16px 28px;
        width:100%; height:auto;
        line-height:map-get($line_heights, _base);
        color:map-get($colors, _01-01);
        font-family:map-get($font_families, _01);
        font-size:100%; font-size:16px;
        background:none;
        border:none;
        border-radius:6px;
        box-shadow:0 0 0 0 transparent inset !important;
        box-sizing:border-box;
        cursor:pointer;
        appearance:none;

        &:disabled {
            color:map-get($colors, _01-01);
            cursor:default;

            & + .chevron {
                opacity:.24;
            }
        }
    }

    .chevron {
        @include animate;
        @include flex-column;
        @include absolute(-1);
        justify-content:center;
        align-items:center;
        top:-1px; right:-1px; bottom:-1px;
        width:48px;
        background:map-get($colors, _03-01);
        border-radius:6px;

        svg {
            display:block;
            width:12px; height:12px;
            fill:#FFF !important;
            transform:rotate(90deg);
        }
    }
}


/* ------------------------------------------------------------------------------------------------------------------------ */
/* INPUT RADIO / CHECKBOX ------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.input-radio,
.input-checkbox {
    @include animate;
    @include relative;
    @include flex-row;

    input {
        position:absolute;
        top:0; left:0;
        z-index:1;
        margin:0; padding:0;
        width:24px; height:24px;
        line-height:1;
        color:map-get($colors, _01-01);
        font-size:100%; font-size:16px;
        box-sizing:border-box;
        background:none;
        border:0 solid;
        border-radius:0;
        opacity:0;
        cursor:pointer;
        appearance:none;

        &:checked:not(:disabled) + span {
            background:map-get($colors, _03-01);
            border:2px solid map-get($colors, _03-01);

            &:after,
            svg {
                opacity:1;
            }
        }

        &:disabled {
            cursor:default;

            & + span + label {
                opacity:.24;
                cursor:default;
            }
        }
    }

    input + span {
        @include animate;
        @include flex-column;
        justify-content:center;
        align-items:center;
        flex-shrink:0;
        width:24px; height:24px;
        border:1px solid map-get($colors, _01-02);
        box-sizing:border-box;

        &:after,
        svg {
            @include animate;
            display:block;
            opacity:0;
        }
    }

    input + span + label {
        flex:1 1 auto;
        flex-direction:column;
        margin:0; padding:0 0 0 18px;
        line-height:1.2;
        color:map-get($colors, _01-01);
        font-size:16px;
        text-align:left;
        cursor:pointer;
    }

    & + .field-feedback:before {
        left:6px;
    }
}

.input-radio {

    input + span {
        border-radius:100%;

        &:after {
            content:"";
            width:8px; height:8px;
            background:map-get($colors, _01-01);
            border-radius:100%;
        }
    }
}

.input-checkbox {

    input + span {

        svg {
            width:12px; height:12px;
            fill:#FFF;
        }
    }
}


/*
 * Form Light Modifier
 */

.form-light {

    .input-radio,
    .input-checkbox {

        input + span {
            border-color:#FFF;
        }
    }
    .input-radio input + span:after {
        background:#FFF;
    }

    .input-checkbox input + span svg {
        fill:#FFF;
    }
}


/* ------------------------------------------------------------------------------------------------------------------------ */
/* GOOGLE RECAPTCHA ------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.g-recaptcha {
    display:none;
}


/* ------------------------------------------------------------------------------------------------------------------------ */
/* FORM ------------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.form-wrapper {
}
    .form-wrapper_pre-text {
        padding:0 0 32px 18px;
        line-height:map-get($line_heights, _base);
        color:map-get($colors, _02-02);
        font-size:16px;

        @media screen and (max-width:768px)
        {
            padding-bottom:24px;
        }
    }

    .form {
        margin-top:-24px;

        @media screen and (max-width:768px)
        {
            margin-top:-18px;
        }

        &.is-loading .field {
            opacity:.24;
            pointer-events:none;
        }

        a {
            @include animate;
            text-decoration:underline;

            @media screen and (min-width:769px)
            {
                &:hover,
                &:focus {
                    color:map-get($colors, _01-01);
                    text-decoration:none;
                }
            }
        }

        .field {
            @include animate;
        }
    }
        .form-btn_wrapper {
            @include flex-row;
            justify-content:center;
            padding-top:48px;

            @media screen and (max-width:768px)
            {
                padding-top:24px;
            }

            .btn {
                font-size:24px;
                background:no-repeat;
                border:none;
                appearance:none;

                .btn-icon,
                .btn-icon svg {
                    width:32px; height:32px;
                }
            }
        }
        .form-bottom {
            padding-top:48px;
            line-height:map-get($line_heights, _small);
            font-size:map-get($font_sizes, _small);
            text-align:center;

            @media screen and (max-width:768px)
            {
                padding-top:24px;
            }
        }
