/* ------------------------------------------------------------------------------------------------------------------------ */
/* TEXT MEDIA SLIDER ------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */

.pb-block-tms {
    @media screen and (max-width: 768px) {
        padding-left: 0;
        padding-right: 0;
    }

    &.pb-block_has-bg {
        @media screen and (max-width: 768px) {
            padding: 0;

            .pb-block_content {
                margin-bottom: -32px;
            }
        }
    }

    .pb-block_header {
        @media screen and (max-width: 768px) {
            padding-left: 24px;
            padding-right: 24px;
            box-sizing: border-box;
        }
    }

    .pb-block_header + .pb-block_content {
        padding-top: 96px;

        @media screen and (max-width: 1220px) {
            padding-top: 48px;
        }
    }

    .pb-block_content {
        @include flex-row;
        flex-wrap: wrap;

        @media screen and (min-width: 769px) {
            justify-content: center;
            align-items: center;
        }
    }
}
.tms-slider {
    @media screen and (min-width: 769px) {
        width: calc(100% - 224px);
        max-width: map-get($widths, _medium_large);
    }
    @media screen and (max-width: 768px) {
        width: 100%;
        order: -1;
    }
}

/*
 * Intro
 */

.pb-block-tms-heading {
    line-height: 37px;
    color: map-get($colors, _02-01);
    font-size: 30px;
    text-align: center;
}

.pb-block-tms-text {
    padding-top: 24px;
}

/*
 * Items
 */

.tms-slide-content {
    overflow: hidden;

    @media screen and (min-width: 1025px) {
        @include flex-row;
    }
    @media screen and (max-width: 1024px) {
        @include flex-column;
        height: 100%;
    }
}

.tms-slide-lhc,
.tms-slide-rhc {
    @media screen and (min-width: 1025px) {
        width: 50%;
    }
}

.tms-slide-lhc {
    @include flex-row;
    @include relative(-1);

    &:before {
        @include aspect-ratio("1 / 1"); // Aspect ratio: 1:1

        @media screen and (max-width: 1024px) and (min-width: 769px) {
            @include aspect-ratio("9 / 16"); // Aspect ratio: 16:9
        }
    }

    .wrapper-video,
    .media-picture,
    .media-img {
        @include full-size;
    }
    .wrapper-video {
        overflow: unset;
    }
    .wrapper-video iframe,
    .media-img {
        height: 100% !important;
        object-fit: cover;
        font-family: "object-fit:cover;";

        @media screen and (min-width: 1025px) {
            width: calc(100% + 192px);
            border-bottom-left-radius: 48px;
        }
        @media screen and (max-width: 1220px) and (min-width: 1025px) {
            width: calc(100% + 96px);
        }
    }
}

.tms-slide-rhc {
    align-self: center;
    padding: 48px;
    background: map-get($colors, _02-01);
    box-sizing: border-box;

    @media screen and (min-width: 1025px) {
        margin: 96px 0;
    }
    @media screen and (min-width: 769px) {
        border-bottom-right-radius: 48px;
    }
    @media screen and (max-width: 1220px) and (min-width: 1025px) {
        margin: 32px 0;
        padding: 32px;
    }
    @media screen and (max-width: 1024px) and (min-width: 769px) {
        padding: 48px;
    }
    @media screen and (max-width: 1024px) {
        flex: 1 1 auto;
    }
    @media screen and (max-width: 768px) {
        padding: 48px 24px 80px;
    }
}
.tms-slide-heading {
    padding-bottom: 6px;
    line-height: 37px;
    color: #fff;
    font-size: 30px;
    border-bottom: 2px solid currentColor;
}
.tms-slide-text {
    padding-top: 32px;

    @media screen and (max-width: 768px) {
        padding-top: 24px;
    }
}

/*
 * Slider
 */

.tms-slider_prev,
.tms-slider_next {
    @include flex-column;
    @include relative;
    justify-content: center;
    align-items: center;
    width: 64px;
    height: 64px;
    border-radius: 100%;
    overflow: hidden;

    @media screen and (max-width: 768px) {
        z-index: 2;
        margin-top: -32px;
        box-shadow: 0px -3px 6px rgba(#000, 0.12);
    }

    &:not([class*="bg"]) {
        background: map-get($colors, _05-01) !important;
    }

    &:before {
        @include animate;
        @include full-size(-1);
        content: "";
        background: rgba(#000, 0.12);
        opacity: 0;
    }

    svg {
        display: block;
        width: 18px;
        height: 18px;
        fill: #fff;
    }

    @media screen and (min-width: 769px) {
        &:not(.swiper-button-disabled):hover:before,
        &:not(.swiper-button-disabled):focus:before {
            opacity: 1;
        }
    }
}
.tms-slider_prev {
    @media screen and (max-width: 768px) {
        margin-left: 24px;
        margin-right: calc(50vw - 88px);
    }
}
.tms-slider_next {
    @media screen and (max-width: 768px) {
        margin-left: calc(50vw - 88px);
        margin-right: 24px;
    }
}

.tms-slider_pagination {
    @include relative;
    margin: 84px 24px 0;
    padding: 0;
    width: auto;

    @media screen and (max-width: 768px) {
        // margin:61px auto 0;
        margin: 24px auto 0;
    }

    &:before {
        @include absolute(-1);
        content: "";
        top: 10px;
        left: 11px;
        right: 11px;
        height: 2px;
        background: map-get($colors, _01-02);
    }

    .swiper-pagination-bullet {
        @include relative;
        margin: 0 96px;
        width: 22px;
        height: 22px;
        background: none;

        @media screen and (max-width: 768px) {
            margin: 0 48px;
        }

        &:first-child {
            margin-left: 0;
        }
        &:last-child {
            margin-right: 0;
        }

        &:before {
            width: 10px;
            height: 10px;
            background: currentColor;
            opacity: 1;
        }
        &:after {
            @include animate;
            @include absolute(-1);
            content: "";
            top: 1px;
            left: 1px;
            width: 16px;
            height: 16px;
            background: #fff;
            border: 2px solid currentColor;
            border-radius: 100%;
            opacity: 0;
        }

        & > span {
            @include animate;
            @include absolute;
            bottom: 30px;
            left: 50%;
            line-height: 27px;
            color: currentColor;
            font-size: 18px;
            font-weight: 600;
            white-space: nowrap;
            transform: translateX(-50%);

            @media screen and (max-width: 768px) {
                display: none;
                // line-height:18px;
                // font-size:14px;
            }
        }

        &.swiper-pagination-bullet-active {
            &:after {
                opacity: 1;
            }
        }
    }
}
html {
    &[lang="de-DE"] {
        .tms-slider_pagination {
            .swiper-pagination-bullet {
                & > span {
                    white-space: normal;
                    min-width: 190px;
                    text-align: center;
                    font-size: 14px;
                }
            }
        }
    }
}

/*
 * Ad-hoc
 */

.pb-block-hero-slider + .pb-block-tms,
.pb-block-breadcrumbs + .pb-block-tms {
    @media screen and (max-width: 768px) {
        padding-top: 0;
    }
}
