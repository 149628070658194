/* ------------------------------------------------------------------------------------------------------------------------ */
/* CONTACT FORM ----------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.pb-block-enquiry-form {
    background:map-get($colors, _01-04);

    .field-heading {

        @media screen and (min-width:769px) {
            padding-top:24px;
        }
    }
}
    .pb-block-enquiry-form-lhc,
    .pb-block-enquiry-form-rhc {

        @media screen and (max-width:1056px) and (min-width:769px) {
            width:calc((6 / 12) * 100%) !important;
        }
    }
    .pb-block-enquiry-form-rhc {

        @media screen and (max-width:768px) {
            padding-top:48px;
        }
    }

.pb-block-enquiry-form-heading {
    @include relative;
    margin-bottom:48px; padding-bottom:12px;
    line-height:37px;
    color:map-get($colors, _01-01);
    font-size:30px;
    font-weight:600;
    border-bottom:3px solid map-get($colors, _03-01);

    @media screen and (max-width:768px) {
        margin-bottom:24px;
    }

    &:after {
        @include absolute;
        content:"";
        right:0; bottom:-6px;
        width:9px; height:9px;
        background:map-get($colors, _03-01);
        border-radius:100%;
    }
}

.pb-block-enquiry-form-picture {
    overflow:hidden;

    @media screen and (min-width:769px) {
        border-bottom-left-radius:48px;
    }
}

.form-enquiry-heading {
    line-height:27px;
    color:map-get($colors, _04-02);
    font-size:22px;
    font-weight:600;
}
.form-enquiry-btn {
    margin-top:48px;

    @media screen and (max-width:768px) {
        margin-top:24px;
    }
}

.form-enquiry-address-fields {
    padding:48px 0 24px;
}
