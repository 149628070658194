/* ------------------------------------------------------------------------------------------------------------------------ */
/* COLOUR MATCH ----------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.pb-block-colour-match {

    .field-heading {

        @media screen and (min-width:769px) {
            padding-top:24px;
        }
    }
}
    .pb-block-colour-match-rhc {

        @media screen and (max-width:768px) {
            padding-top:48px;
        }
    }

.pb-block-colour-match-heading {
    @include relative;
    margin-bottom:48px; padding-bottom:12px;
    line-height:37px;
    color:map-get($colors, _04-02);
    font-size:30px;
    font-weight:600;
    border-bottom:3px solid map-get($colors, _04-02);

    @media screen and (max-width:768px) {
        margin-bottom:24px;
    }

    &:after {
        @include absolute;
        content:"";
        right:0; bottom:-6px;
        width:9px; height:9px;
        background:map-get($colors, _04-02);
        border-radius:100%;
    }
}

.pb-block-colour-match-intro {
    padding-bottom:48px;
}

.pb-block-colour-match-picture {
    overflow:hidden;

    @media screen and (min-width:769px) {
        border-bottom-left-radius:48px;
    }
}

.form-colour-match {

    &.step-2 {

        .field-select .chevron {
            background:map-get($colors, _04-02);
        }

        .input-radio input:checked:not(:disabled) + span ,
        .input-checkbox input:checked:not(:disabled) + span {
            background:map-get($colors, _04-02);
            border:2px solid map-get($colors, _04-02);
        }
    }
}
    .form-colour-match-heading {
        line-height:27px;
        font-size:22px;
        font-weight:400;
    }
    .form-colour-match-btn {
        align-self:flex-start;
        margin-top:48px;

        @media screen and (max-width:768px) {
            margin-top:24px;
        }
    }

.pb-block-colour-match-pagination {
    @include flex-row;
    align-items:center;

    &:after {
        content:"";
        flex:1 1 auto;
        margin-left:24px;
        height:1px;
        background:map-get($colors, _01-01);
    }

    & > span:not(.pb-block-colour-match-pagination-chevron),
    & > button {
        @include flex-row;
        align-items:center;
        cursor:pointer;

        &:not(:last-child) {
            margin-right:24px;
        }

        & > span {
            @include animate;
            @include flex-column;
            justify-content:center;
            align-items:center;
            flex-shrink:0;
            margin-right:12px;
            width:32px; height:32px;
            color:#FFF;
            font-size:18px;
            font-weight:700;
            background:map-get($colors, _04-02);
            border-radius:100%;
        }

        &.is-active > span {
            width:48px; height:48px;
            font-size:24px;
            background:map-get($colors, _03-01);
        }

        &.is-active,
        &.is-disabled {
            cursor:default;
        }
    }

    & > button {

        @media screen and (min-width:769px) {
            &:hover > span,
            &:focus > span {
                background:map-get($colors, _03-01);
            }
        }
    }
}
    .pb-block-colour-match-pagination-chevron {
        margin-right:24px;

        svg {
            display:block;
            width:18px; height:18px;
            fill:map-get($colors, _01-01);
        }
    }

.pb-block-colour-match-success {

    h2 {
        @include flex-row;
        align-items:center;
        line-height:34px;
        color:map-get($colors, _04-02);
        font-size:28px;
        font-weight:700;
        text-transform:uppercase;

        span {
            @include flex-column;
            justify-content:center;
            align-items:center;
            margin-left:12px;
            width:48px; height:48px;
            background:map-get($colors, _valid);
            border-radius:100%;

            svg {
                display:block;
                width:18px; height:18px;
                fill:#FFF;
            }
        }
    }
}
