/* ------------------------------------------------------------------------------------------------------------------------ */
/* ARTICLE ---------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.article-meta {
    // margin-bottom:-48px;
    width:100%; max-width:map-get($widths, _medium_small);
}

    .article-heading {
        line-height:43px;
        color:map-get($colors, _04-02);
        font-size:35px;

        @media screen and (max-width:768px) {
            line-height:38px;
            font-size:32px;
        }
    }

    .article-date {
        padding-top:24px;
        line-height:27px;
        font-size:22px;
        font-weight:300;

        @media screen and (max-width:768px) {
            line-height:map-get($line_heights, _small);
            font-size:map-get($font_sizes, _small);
        }
    }
