/* ------------------------------------------------------------------------------------------------------------------------ */
/* FAQS ------------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.pb-block-faqs {

    & > .pb-block_content {

        @media screen and (max-width:768px) {
            padding-top:0; padding-bottom:0;
        }
    }
}


/*
 * Items
 */

.pb-block-faq {

    &:not(:first-child) {
        padding-top:48px;

        @media screen and (max-width:768px) {
            padding-top:24px;
        }
    }

    &.open .pb-block-faq-chevron svg {
        transform:rotate(-90deg);
    }
}
    .pb-block-faq-heading {
        @include flex-row;
        justify-content:space-between;
        align-items:center;
        padding:24px 48px;
        line-height:39px;
        color:#FFF;
        font-size:32px;
        font-weight:600;
        background:map-get($colors, _02-01);
        border-top-right-radius:24px;
        cursor:pointer;

        @media screen and (max-width:768px) {
            padding:20px 24px;
            line-height:26px;
            font-size:18px;
        }
    }
        .pb-block-faq-chevron {
            flex-shrink:0;
            margin-left:48px;
            width:24px; height:24px;

            @media screen and (max-width:768px) {
                margin-left:24px;
            }

            svg {
                @include animate;
                display:block;
                width:24px; height:24px;
                fill:#FFF !important;
                transform:rotate(90deg);
            }
        }

    .pb-block-faq-text {
        padding:24px 48px;
        background:map-get($colors, _01-03);
        border-bottom-left-radius:24px;

        @media screen and (max-width:768px) {
            padding:18px 24px;
            font-size:16px;
        }
    }


/*
 * Background
 */

.pb-block-faqs-bg {
    max-height:1200px;
}
