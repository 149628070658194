/* ------------------------------------------------------------------------------------------------------------------------ */
/* SOCIAL FEED ------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */

.pb-block-social-feed {
    overflow:hidden;

    & > .pb-block_content {

        @media screen and (max-width:768px) {
            padding-bottom:0;
        }
    }
}


/*
 * Heading
 */

.pb-block-social-feed-heading {
    @include relative;
    padding-bottom:12px;
    line-height:37px;
    color:map-get($colors, _02-01);
    font-size:30px;
    border-bottom:3px solid map-get($colors, _05-01);

    &:after {
        @include absolute;
        content:"";
        right:0; bottom:-6px;
        width:9px; height:9px;
        background:map-get($colors, _05-01);
        border-radius:100%;
    }
}


/*
 * Grid
 */

.pb-block-social-feed-grid {

    @media screen and (min-width:1221px) {
        padding-top:96px;
    }
    @media screen and (max-width:1220px) and (min-width:769px) {
        padding-top:48px;
    }
    @media screen and (max-width:1024px) and (min-width:769px) {

        & > .g-column {
            width:100%;
        }
    }
}
    .pb-block-social-feed-rhc {

        @media screen and (min-width:769px) {
            @include flex-row;
            flex-wrap:wrap;
            justify-content:space-between;
            flex:1 1 auto;
        }
        @media screen and (max-width:1024px) and (min-width:769px) {
            padding-top:24px;
        }

        .testimonial-card {
            margin-bottom:24px;
        }
    }
