/* ------------------------------------------------------------------------------------------------------------------------ */
/* LOGOS ------------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */

.pb-block-logos {
}
    .pb-block-logos-grid {
        justify-content:center;
        margin-top:-24px;

        @media screen and (max-width:768px) {
            justify-content:space-between;

            & > .g-column {
                width:calc(50% - 12px);
            }
        }
    }
        .pb-block-logos-divider {
            margin:48px 0 24px;
            height:2px;
            background:map-get($colors, _04-02);

            @media screen and (max-width:768px) {
                width:100% !important;
            }
        }

.pb-block-logo {
    @include flex-row;
    @include relative;
    flex:1 1 auto;
    justify-content:center;
    align-items:center;
    margin-top:24px;

    &:before {
        @include aspect-ratio('9 / 16'); // Aspect ratio: 16:9
    }
}
    .pb-block-logo-link {
        @include full-size(2);
    }
    .pb-block-logo-picture {
        @include full-size;
    }
        .pb-block-logo-img {
            height:100% !important;
            object-fit:contain; font-family:'object-fit:contain;';
        }
