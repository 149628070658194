/* ------------------------------------------------------------------------------------------------------------------------ */
/* LAZYLOAD --------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.lazyload-wrapper {
    display:block;

    &.animated {
        overflow:hidden;

        img {
            @include animate;
        }

        @media screen and (min-width:769px) {
            &:hover img,
            &:focus img {
                transform:scale(1.1);
            }
        }
    }
}
.lazyload-wrapper img {
    @include full-width;
}

/*
.lazyload-wrapper {
    @include relative;
    display:block;

    picture,
    img {
        @include animate;
        @include relative;
        @include full-width;
        opacity:0;

        &.lazyloaded {
            opacity:1;
        }
    }
    picture.lazyloaded img {
        opacity:1;
    }
}
    .lazyload-caption {
    }
*/


/* ------------------------------------------------------------------------------------------------------------------------ */
/* NO-JS ------------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */

/*
.no-js {

    .lazyload-wrapper picture,
    .lazyload-wrapper img {
        opacity:1;
    }
}
*/
