/* ------------------------------------------------------------------------------------------------------------------------ */
/* LOGO ------------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.logo-mark { fill:map-get($colors, _01-03); }

.logo-symbol-path-01 { fill:map-get($colors, _04-03); }
.logo-symbol-path-02 { fill:map-get($colors, _04-01); }
.logo-symbol-path-03 { fill:map-get($colors, _02-02); }
.logo-symbol-path-04 { fill:map-get($colors, _03-01); }
.logo-symbol-path-05 { fill:map-get($colors, _06-01); }
.logo-symbol-path-06 { fill:map-get($colors, _05-01); }
.logo-symbol-path-07 { fill:map-get($colors, _04-02); }
