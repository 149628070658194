/* ------------------------------------------------------------------------------------------------------------------------ */
/* SEARCH ----------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

/*
 * Search Form
 */

.searchform {

    @media screen and (min-width:769px) {
        @include absolute;
        top:24px; left:24px;
        background:map-get($colors, _01-03);
        border-radius:999px; border-bottom-right-radius:0;
    }
    @media screen and (max-width:768px) {
        @include flex-row;
        align-items:center;
        position:fixed;
        left:0; right:0; bottom:0;
        z-index:3;
        padding:12px 24px;
        background:map-get($colors, _02-01);
    }

    input {
        @include relative;
        @include animate;
        line-height:1;
        font-size:16px;
        font-weight:600;
        width:100%;
        box-sizing:border-box;

        @media screen and (min-width:769px) {
            padding:9px 18px 9px 42px;
            max-width:180px;

            &:hover,
            &:focus {
                max-width:312px;
            }
        }
        @media screen and (max-width:768px) {
            margin:0 18px; padding:9px 18px;
            background:#FFF;
            border-radius:999px; border-bottom-left-radius:0;
        }
    }

    input + svg {
        display:block;
        flex-shrink:0;
        width:16px; height:16px;

        @media screen and (min-width:769px) {
            @include absolute(-1);
            top:50%; left:18px;
            fill:map-get($colors, _01-01);
            transform:translateY(-50%);
        }
        @media screen and (max-width:768px) {
            order:-1;
            fill:#FFF;
        }
    }

    button {
        @include flex-column;
        justify-content:center;
        align-items:center;
        flex-shrink:0;
        width:37px; height:37px;
        background:map-get($colors, _03-01);
        border-radius:100%;

        svg {
            display:block;
            width:16px; height:16px;
            fill:#FFF;
            transform:rotate(-90deg);
        }
    }
}


/*
 * Search Results
 */

.pb-search-results {
}
    .pb-search-results-heading {
        line-height:34px;
        color:map-get($colors, _04-02);
        font-size:28px;
        font-weight:700;
        text-transform:uppercase;
    }

    .search-result-item {

        @media screen and (min-width:769px) {
            @include flex-row;
        }

        &:first-child {
            padding-top:48px;

            @media screen and (max-width:768px) {
                padding-top:24px;
            }
        }
        &:not(:first-child) {
            margin-top:48px; padding-top:48px;
            border-top:1px solid map-get($colors, _01-03);

            @media screen and (max-width:768px) {
                margin-top:24px; padding-top:24px;
            }
        }
    }

        .search-result-item-thumb {
            @include relative;
            display:block;
            align-self:flex-start;
            margin-bottom:24px;

            @media screen and (min-width:769px) {
                flex-shrink:0;
                padding-right:48px;
                width:100%; max-width:312px;
            }
            @media screen and (max-width:768px) {
                margin-bottom:36px;
            }

            &:before {
                @include absolute(-1);
                content:"";
                left:-24px; bottom:-24px;
                width:128px; height:128px;
                background:currentColor;
                border-bottom-left-radius:24px;

                @media screen and (max-width:768px) {
                    left:-12px; bottom:-12px;
                    border-bottom-left-radius:12px;
                }
            }
        }

        .search-result-item-content {
            flex:1 1 auto;
            color:map-get($colors, _01-01);
        }

            .search-result-item-meta {
                @include flex-row;
                padding-bottom:12px;
                line-height:19px;
                font-size:16px;

                strong {
                    padding-right:8px;
                }
            }

            .search-result-item-heading {
                line-height:map-get($line_heights, _small);
                font-size:22px;
                font-weight:700;
            }

            .search-result-item-desc {
                padding-top:18px;
            }

            .search-result-item-wrapper {
                @include flex-row;
                padding-top:24px;
            }
