/* ------------------------------------------------------------------------------------------------------------------------ */
/* PAGE BUILDER ----------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.page-builder {
    &__content {}
}


/*
 * Block
 */

.pb-block {
    padding:96px;

    @media screen and (max-width:1220px) and (min-width:769px) {
        padding:48px;
    }
    @media screen and (max-width:768px) {
        padding:48px 24px;
    }

    // Background
    &.pb-block_has-bg {
        @include relative;

        &:last-child {
            margin-bottom:-96px;

            @media screen and (max-width:1220px) {
                margin-bottom:-48px;
            }
        }
    }

    // No Background
    &:not(.pb-block_has-bg) {

        & + .pb-block:not(.pb-block_has-bg):not(.pb-block-spacer) {
            margin-top:-96px !important;

            @media screen and (max-width:1220px) and (min-width:769px) {
                margin-top:-48px !important;
            }
            @media screen and (max-width:768px) {
                margin-top:-48px !important;
            }
        }

        &:last-child {
            padding-bottom:0;
        }
    }
}


/*
 * Background
 */

.pb-block_background {
    @include full-size(-1);
    overflow:hidden;
}
    .pb-block_background_picture,
    .pb-block_background_image {
        @include full-size;

        &.bg-center {
            @include flex-row;
            top:50%; bottom:auto;
            transform:translateY(-50%);

            @media screen and (max-width:768px) {
                width:1920px;
            }

            &:before {
                @include aspect-ratio('1080 / 1920'); // Aspect ratio: 16:9
            }
        }
    }
    .pb-block_background_image {
        position:absolute !important;
        height:100% !important;
        object-fit:cover; font-family:'object-fit:cover;';
        pointer-events:none !important;
    }


/*
 * Header / Content / Footer
 */

.pb-block_header,
.pb-block_content,
.pb-block_footer {

    &:not(:first-child) {
        padding-top:48px;

        @media screen and (max-width:768px) {
            padding-top:24px;
        }
    }
}


/*
 * Graphic Elements
 */

.pb-block-graphic-elem-wrapper {
    @include absolute(-1);

    &.pos-left {
        left:0; bottom:0;

        @media screen and (min-width:1921px) {
            left:calc((100vw - 1622px) / 2 - 196px);
        }
    }
    &.pos-right {
        top:0; right:0;

        @media screen and (min-width:1921px) {
            right:calc((100vw - 1622px) / 2 - 196px);
        }
    }
}
    .pb-block-graphic-elem {
        @include absolute(-1);
        width:512px; height:96px;
        border:2px solid map-get($colors, _02-02);
        border-top-right-radius:32px; border-bottom-left-radius:32px;
        box-sizing:border-box;

        &.graphic-elem-left {
            margin-left:-380px;
            transform:rotate(-45deg);

            @media screen and (max-width:1881px) and (min-width:1221px) {
                margin-left:-414px;
            }
            @media screen and (max-width:1220px) and (min-width:769px) {
                margin-left:-444px;
            }

            &.graphic-elem-top {
                left:15px; bottom:97px;
                border-color:map-get($colors, _04-03);
            }
            &.graphic-elem-middle {
                left:0; bottom:16px;
                border-color:map-get($colors, _06-01);
            }
            &.graphic-elem-bottom {
                left:-15px; bottom:-66px;
            }
        }

        &.graphic-elem-right {
            margin-right:-380px;
            transform:rotate(-45deg);

            @media screen and (max-width:1881px) and (min-width:1221px) {
                margin-right:-414px;
            }
            @media screen and (max-width:1220px) and (min-width:769px) {
                margin-right:-444px;
            }

            &.graphic-elem-top {
                top:-66px; right:-15px;
            }
            &.graphic-elem-middle {
                top:16px; right:0;
            }
            &.graphic-elem-bottom {
                top:97px; right:15px;
            }
        }
    }

/*
 * Spacer
 */

.pb-block-spacer {
    padding:0;

    &.height-normal {
        height:96px;

        @media screen and (max-width:1220px) {
            height:48px;
        }
    }
    &.height-medium {
        height:48px;
    }
    &.height-small {
        height:24px;
    }
}


/*
 * Triangles
 */

.pb-block_has-triangle {
    @include relative;
}

.pb-block-triangle {
    @include absolute;
    top:0; left:50%;
    transform:translateX(-50%);
    width:0; height:0;
    border-style:solid; border-width:32px 32px 0 32px; border-color:currentColor transparent transparent transparent;
}


/*
 * Preview (No Fields)
 */

.pb-block_preview {
    padding:24px;
    color:#333;
    font-size:14px;
    font-weight:700;
    text-transform:uppercase;
    background:#EEE;
    border:1px solid #ADB2AD;
}
