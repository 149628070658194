/* ------------------------------------------------------------------------------------------------------------------------ */
/* ACCREDITATIONS --------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.pb-block-accreditations {
}

.pb-block-accreditations-heading {
    line-height:40px;
    color:map-get($colors, _02-01);
    font-size:32px;
    font-weight:600;
    text-align:center;
}

.pb-block-accreditations-grid {
    @include flex-row;
    flex-wrap:wrap;
    justify-content:center;
    margin-top:-24px; padding-top:48px;
}
    .pb-block-accreditation {
        @include flex-row;
        @include relative;
        flex:1 1 auto;
        justify-content:center;
        align-items:center;
        margin:24px 24px 0;

        @media screen and (min-width:769px) {
            min-width:140px; max-width:260px;
        }
        @media screen and (max-width:768px) {
            width:calc(50% - 48px);
        }

        &:before {
            @include aspect-ratio('9 / 16'); // Aspect ratio: 16:9
        }
    }
        .pb-block-accreditation-link {
            @include full-size(2);
        }
        .pb-block-accreditation-picture {
            @include full-size;
        }
            .pb-block-accreditation-img {
                height:100% !important;
                object-fit:contain; font-family:'object-fit:contain;';
            }
