.gws-loading {
    @include relative;

    &:after {
        @include animate;
        @include absolute(2);
        content:"";
        top:50%; left:50%;
        z-index:1;
        width:32px; height:32px;
        border:2px solid map-get($colors, _02-01); border-top:2px solid transparent; border-radius:50%;
        box-sizing:border-box;
        transform:translate(-50%, -50%);
        animation:spin 600ms linear infinite;
    }
}
