/* ------------------------------------------------------------------------------------------------------------------------ */
/* FOOTER ----------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.footer {
    margin-top:96px; padding:96px 96px 48px;
    color:#FFF;
    background:$bc_gradient;
    border-top:4px solid map-get($colors, _06-01);

    @media screen and (max-width:1220px) and (min-width:769px) {
        padding:48px;
    }
    @media screen and (max-width:768px) {
        margin-top:48px; padding:48px 24px;
        font-size:14px;
    }
}

    .footer-grid.g-grid {

        @media screen and (max-width:1220px) and (min-width:769px) {
            margin-left:-24px; margin-right:-24px;
        }

        & > .g-column {

            @media screen and (max-width:1220px) and (min-width:769px) {
                padding-left:24px; padding-right:24px;

                &:not(.width_12-12) {
                    width:calc((4 / 12) * 100%);
                }
            }
            @media screen and (max-width:768px) {

                &:not(:first-child) {
                    padding-top:48px;
                }
                &:last-child {
                    padding-top:24px;
                }
            }
        }
    }

        .footer-ca strong {

            @media screen and (max-width:768px) {
                font-size:16px;
            }
        }

        .footer-cc {

            @media screen and (min-width:769px) {
                flex-direction:row !important;
                padding-top:96px;
            }
            @media screen and (max-width:768px) {
                padding-top:24px !important;
            }
        }
            .footer-cc-lhc {

                @media screen and (min-width:769px) {
                    flex:1 1 auto;
                    padding-right:96px;
                }
            }
            .footer-cc-rhc {
                @include flex-row;
                align-items:center;

                .IN-widget {
                    margin-right:12px;
                }
            }


/*
 * Footer Newsletter
 */

.footer-newsletter {

    .form-newsletter {
        padding:32px 0 0;
        width:auto;
    }
}
    .footer-newsletter-intro {
        line-height:map-get($line_heights, _small);

        strong,
        small {
            display:block;
            font-size:16px;
        }
        small {
            padding-top:9px;

            @media screen and (max-width:768px) {
                font-size:14px;
            }
        }
    }


/*
 * Footer Lang
 */

.footer-lang {
    @include flex-row;
    padding-bottom:24px;
}

    .current-lang {
        @include flex-row;
        align-items:center;
        padding-right:24px;

        @media screen and (max-width:768px) {
            padding-right:12px;
        }

        svg {
            display:block;
            flex-shrink:0;
            width:32px; height:32px;
            border-radius:100%;
            overflow:hidden;
        }

        span {
            padding-left:12px;
            text-transform:uppercase;
        }
    }

    .nav-lang {
        @include relative;
    }

        .nav-lang-trigger {
            @include animate;
            color:#FFF;
            cursor:pointer;

            @media screen and (max-width:768px) {
                @include flex-row;
                align-items:center;
            }

            svg {
                display:none;

                @media screen and (max-width:768px) {
                    display:block;
                    flex-shrink:0;
                    margin-top:2px;
                    padding-left:12px;
                    width:12px; height:12px;
                    fill:#FFF;
                }
            }

            &:not(:disabled):hover,
            &:not(:disabled):focus {

                @media screen and (min-width:769px) {
                    color:map-get($colors, _06-01);
                }

                & + .nav-lang-list {
                    opacity:1;
                    visibility:visible;
                }
            }
        }

        .nav-lang-list {
            @include animate;
            @include absolute;
            left:50%; bottom:40px;
            padding:24px;
            width:fit-content; min-width:100%;
            line-height:map-get($line_heights, _small);
            background:rgba(#000, .88);
            list-style:none;
            opacity:0;
            visibility:hidden;
            transform:translateX(-50%);

            &:before {
                @include absolute;
                content:"";
                left:0; bottom:-8px; right:0;
                height:8px;
            }
            &:after {
                @include absolute;
                content:"";
                left:50%; bottom:-6px;
                transform:translateX(-50%);
                width:0; height:0;
                border-style:solid; border-width:6px 6px 0 6px; border-color:rgba(#000, .88) transparent transparent transparent;
            }

            @media screen and (min-width:769px) {
                &:hover,
                &:focus {
                    opacity:1;
                    visibility:visible;
                }
            }
        }
            .nav-lang-link {
                @include animate;
                color:#FFF;
                font-size:16px;

                @media screen and (min-width:769px) {
                    &:not(.is-current):hover,
                    &:not(.is-current):focus {
                        color:map-get($colors, _04-03);
                    }
                }

                &.is-current {
                    color:rgba(#FFF, .48);
                    cursor:default;
                }
            }


/*
 * Footer Social
 */

.footer-social-link {
    @include flex-column;
    justify-content:center;
    align-items:center;
    width:32px; height:32px;
    border-radius:100%;

    &:not(:last-child) {
        margin-right:12px;
    }

    svg {
        display:block;
        flex-shrink:0;
        width:16px; height:16px;
        fill:#FFF;
    }

    &.footer-social-linkedin { background:map-get($colors, _linkedin); }
    &.footer-social-twitter { background:map-get($colors, _twitter); }
    &.footer-social-facebook { background:map-get($colors, _facebook); }
}
