/* ------------------------------------------------------------------------------------------------------------------------ */
/* GRID ACCORDION --------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.pb-block-grid-accordion {
    background:map-get($colors, _02-01);
    overflow:hidden;

    &.pb-block_has-triangle:before {
        display:block;
        content:"";
        width:100%; height:32px;
        opacity:0;
    }
}


/*
 * Intro
 */

.pb-block-grid-accordion-heading {
    line-height:39px;
    font-size:32px;
    font-weight:600;
}


/*
 * Grid
 */

.accordion-grid {
    margin-top:-48px; padding-top:48px;
    overflow:hidden;

    @media screen and (min-width:1221px) {

        & > .g-column:nth-child(4n+1) .accordion-grid-item-more {
        }
        & > .g-column:nth-child(4n+2) .accordion-grid-item-more {
            margin-left:calc(-100% - 48px);
        }
        & > .g-column:nth-child(4n+3) .accordion-grid-item-more {
            margin-left:calc(-200% - (48px * 2));
        }
        & > .g-column:nth-child(4n+4) .accordion-grid-item-more {
            margin-left:calc(-300% - (48px * 3));
        }
    }
    @media screen and (max-width:1220px) and (min-width:769px) {

        & > .g-column {
            width:calc((4 / 12) * 100%);
        }
        & > .g-column:nth-child(3n+2) .accordion-grid-item-more {
            margin-left:calc(-100% - 48px);
        }
        & > .g-column:nth-child(3n+3) .accordion-grid-item-more {
            margin-left:calc(-200% - (48px * 2));
        }
    }
    @media screen and (max-width:768px) {
        margin-top:-24px;
    }
}

    .accordion-grid-item {
        padding-top:48px;

        @media screen and (max-width:768px) {
            padding-top:24px;
        }
    }

        .accordion-grid-item-heading {
            padding:12px 24px;
            line-height:26px;
            color:#FFF;
            font-size:22px;
            text-align:center;
            text-transform:uppercase;
            background:map-get($colors, _05-01);

            @media screen and (max-width:768px) {
                line-height:25px;
                font-size:18px;
            }

            span {
                @include flex-column;
                justify-content:center;
                align-items:center;
            }
        }

        .accordion-grid-item-thumb {
            padding:0px 8px 8px;
            background:map-get($colors, _05-01);
            border-bottom-right-radius:24px;
        }
            .accordion-grid-item-picture {
                border-bottom-right-radius:18px;
                overflow:hidden;
            }

        .accordion-grid-item-readmore {
            @include flex-column;
            align-items:center;
            margin:12px auto 0;
            line-height:19px;
            color:#FFF;
            font-size:16px;
            font-weight:500;
            text-align:center;
            text-transform:uppercase;
            fill:#FFF;

            span {
                @include animate;
                display:block;
                padding-bottom:6px;

                & + span {
                    display:none;
                }
            }
            svg {
                @include animate;
                display:block;
                width:18px; height:18px;
                fill:currentColor !important;
                transform:rotate(90deg);
            }

            @media screen and (min-width:769px) {
                &:not(.disabled):not(:disabled):hover,
                &:not(.disabled):not(:disabled):focus {
                    color:map-get($colors, _05-01);
                    fill:map-get($colors, _05-01);
                }
            }

            &.is-active {

                span {
                    display:none;

                    & + span {
                        display:block;
                    }
                }
                svg {
                    transform:rotate(-90deg);
                }
            }
        }

    .accordion-grid-item-more-wrapper {
        @include relative;
    }
        .accordion-grid-item-more-triangle {
            @include absolute;
            top:30px; left:50%;
            width:26px; height:22px;
            transform:translateX(-50%);
            overflow:hidden;

            @media screen and (max-width:768px) {
                top:6px;
            }

            svg {
                @include animate;
                display:block;
                width:inherit; height:inherit;
                fill:map-get($colors, _05-01);
                transform:rotate(180deg) translateY(-22px);
            }
        }
        .accordion-grid-item-more {
            display:none;

            @media screen and (min-width:1221px) {
                width:calc(100% * 4 + (48px * 3));
            }
            @media screen and (max-width:1220px) and (min-width:769px) {
                width:calc(100% * 3 + (48px * 2));
            }

            &.is-active + .accordion-grid-item-more-triangle svg {
                transform:rotate(180deg);
            }
        }
            .accordion-grid-item-more-content {
                margin-top:48px; padding:48px;
                background:#FFF;
                border-top:24px solid map-get($colors, _05-01);

                @media screen and (max-width:768px) {
                    margin-top:24px; padding:24px;
                    font-size:16px;
                }
            }


/*
 * Light Style
 */

 .pb-block-grid-accordion.grid-accordion-light {
    background:map-get($colors, _01-03);

    .accordion-grid-item-heading,
    .accordion-grid-item-thumb {
        background:map-get($colors, _04-02);
    }

    .accordion-grid-item-readmore {
        color:map-get($colors, _01-01);

        @media screen and (min-width:769px) {
            &:not(.disabled):not(:disabled):hover,
            &:not(.disabled):not(:disabled):focus {
                color:map-get($colors, _04-02);
            }
        }
    }

    .accordion-grid-item-more-triangle svg {
        fill:map-get($colors, _04-02);
    }

    .accordion-grid-item-more-content {
        border-top-color:map-get($colors, _04-02);
    }
}
