/* ------------------------------------------------------------------------------------------------------------------------ */
/* SOCIAL ----------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.share-list {
    @include flex-row;
    width:auto; min-width:0; max-width:none;
    list-style:none;
}
    .share-item {
        @include relative;
        overflow:hidden;

        &:not(:last-child) {
            margin-right:12px;
            padding-right:12px;
            border-right:1px solid rgba(#000, .12);
        }
    }
        .share-link {
            @include flex-column;
            @include relative;
            justify-content:center;
            align-items:center;
            width:24px; height:24px;
            background:map-get($colors, _01-03) !important;

            svg {
                @include animate;
                display:block;
                width:18px; height:18px;
                fill:map-get($colors, _04-02);
            }

            @media screen and (min-width:769px) {
                &:not(:disabled):hover,
                &:not(:disabled):focus {

                    svg {
                        fill:map-get($colors, _04-03);
                    }
                }
            }
        }

.share-input {
    @include absolute(-1);
}
