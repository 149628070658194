/* ------------------------------------------------------------------------------------------------------------------------ */
/* RELATED NEWS ----------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.pb-block-related-news {
    background:#000;
}

    .pb-block-related-news-heading {
        line-height:40px;
        color:#FFF;
        font-size:32px;
        text-transform:uppercase;
    }


/*
 * Background
 */

.pb-block-related-news-bg {
    @include full-size(-1);
    overflow:hidden;
}
    .pb-block-related-news-bg-picture {
        @include absolute;
        @include flex-row;
        top:50%; left:0; right:25%;
        transform:translateY(-50%);

        &:before {

            @media screen and (min-width:769px) {
                @include aspect-ratio('1080 / 1920'); // Aspect ratio: 16:9
            }
            @media screen and (max-width:768px) {
                @include aspect-ratio('576 / 768'); // Aspect ratio: 4:3
            }
        }
    }
        .pb-block-related-news-bg-img {
            @include full-size;
            position:absolute !important;
            height:100% !important;
            object-fit:cover; font-family:'object-fit:cover;';
            pointer-events:none !important;
        }

// Archive
.page-builder.pb-archive,
.wp-block-acf-hot-topics {

    .pb-block-related-news-heading {
        text-align:center;
        text-transform:none;
    }

    .pb-block-related-news-bg-picture {
        right:0;
    }
}
