/* ------------------------------------------------------------------------------------------------------------------------ */
/* ARTICLE CARD ----------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.article-card {
    @include flex-column;
    flex:1 1 auto;
    margin-top:48px; padding:12px;
    background:#FFF;
    border-bottom-right-radius:24px;
    box-shadow:0px 3px 6px rgba(#000, .12);

    @media screen and (max-width:1140px) {
        margin-top:24px;
    }
}

    .article-card-thumb {
        display:block;
    }

    .article-card-content {
        @include flex-column;
        flex:1 1 auto;
        padding:24px 20px 36px;

        @media screen and (max-width:768px) {
            padding:18px 12px 12px;
        }
    }
        .article-card-heading {
            line-height:27px;
            color:map-get($colors, _04-02);
            font-size:22px;
            font-weight:600;

            @media screen and (max-width:768px) {
                line-height:25px;
                font-size:18px;
            }
        }
        .article-card-excerpt {
            padding-top:24px;

            @media screen and (max-width:768px) {
                padding-top:18px;
                line-height:24px;
                font-size:16px;
            }
        }
        .article-card-cta-wrapper {
            @include flex-column;
            align-items:center;
            margin-top:auto; padding-top:32px;

            @media screen and (max-width:768px) {
                padding-top:24px;
            }
        }
            .article-card-cta {
                @include animate;
                line-height:1;
                color:map-get($colors, _01-01);
                font-size:18px;
                font-weight:500;
                text-align:center;
                text-transform:uppercase;

                .btn-icon {
                    fill:map-get($colors, _01-01);
                }

                @media screen and (min-width:769px) {
                    &:hover,
                    &:focus {
                        color:map-get($colors, _05-01);

                        .btn-icon {
                            fill:map-get($colors, _05-01);
                        }
                    }
                }
            }


/* ------------------------------------------------------------------------------------------------------------------------ */
/* NEWS CARD -------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.news-card {
    @include flex-row;
    @include relative;
    align-items:flex-end;

    @media screen and (min-width:769px) {
        width:calc(50% - 12px);
    }
    @media screen and (max-width:768px) {

        & + .news-card {
            margin-top:24px;
        }
    }

    &:before {
        @include aspect-ratio('4 / 3'); // Aspect ratio: 3:4
    }
}
    .news-card-link {
        @include full-size(2);
    }
    .news-card-content {
        padding:32px;
        width:100%;
        color:#FFF;
        text-transform:uppercase;
        background:linear-gradient(to bottom, rgba(#000, 0) 0%, rgba(#000, 1) 100%);
        box-sizing:border-box;
    }
        .news-card-date {
            line-height:21px;
            font-size:18px;
            font-weight:300;
        }
        .news-card-heading {
            padding-top:3px;
            line-height:28px;
            font-size:24px;
        }
    .news-card-picture {
        @include full-size(-1);
    }
        .news-card-img {
            height:100% !important;
            object-fit:cover; font-family:'object-fit:cover;';
        }


/* ------------------------------------------------------------------------------------------------------------------------ */
/* SOCIAL CARD ------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */

.social-card {
    flex:1 1 auto;
    padding:32px;
    background:#FFF;

    @media screen and (min-width:769px) {
        @include flex-row;
        box-shadow:0px 3px 6px rgba(#000, .12);
    }
    @media screen and (max-width:768px) {
        margin-top:12px; padding:24px;
        background:rgba(map-get($colors, _01-03), .48);
    }

    &:not(:last-child) {
        margin-bottom:24px;
    }

    a {
        @include animate;
        color:inherit;

        @media screen and (min-width:769px) {
            &:not(.disabled):not(:disabled):hover,
            &:not(.disabled):not(:disabled):focus {
                color:map-get($colors, _05-01);
            }
        }
    }
}
    .social-card-logo {
        display:block;
        flex-shrink:0;
        margin-right:24px;
        width:96px; height:96px;
        background:map-get($colors, _01-03);
        border-radius:100%;
        overflow:hidden;

        @media screen and (max-width:768px) {
            margin:0 auto;
            width:64px; height:64px;
        }
    }
    .social-card-content {

        @media screen and (max-width:768px) {
            @include flex-column;
            align-items:center;
            padding-top:18px;
            text-align:center;
        }
    }
        .social-card-date {
            @include flex-row;
            line-height:17px;
            color:map-get($colors, _01-02);
            font-size:14px;

            span:not(:last-child) {
                padding-right:18px;
            }
        }
        .social-card-account {
            line-height:33px;
            font-size:18px;
            font-weight:700;

            @media screen and (max-width:768px) {
                line-height:21px;
                font-size:16px;
            }
        }
        .social-card-text {
            padding-top:6px;
            line-height:21px;
            font-size:18px;
            font-weight:600;

            @media screen and (max-width:768px) {
                padding-top:12px;
                font-size:14px;
            }
        }


/* ------------------------------------------------------------------------------------------------------------------------ */
/* TESTIMONIAL CARD ------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.testimonial-card {
    @include flex-row;
    @include relative;

    &:before {
        @include aspect-ratio('9 / 16'); // Aspect ratio: 16:9
    }

    .testimonial-wrapper {
        padding:48px;
    }
        .testimonial {
            line-height:map-get($line_heights, _base);
            font-size:map-get($font_sizes, _base);
        }
        .testimonial-author {
            padding:12px 0;
            line-height:21px;
            color:map-get($colors, _06-01);
            font-size:16px;
            border-bottom:3px solid #FFF;
        }
            .testimonial-fullname {
                line-height:map-get($line_heights, _small);
                color:#FFF;
                font-size:map-get($font_sizes, _small);
            }
}
    .testimonial-card-bg-picture {
        @include full-size(-1);
        background:#000;
    }
        .testimonial-card-bg-img {
            height:100% !important;
            opacity:.48 !important;
            object-fit:cover; font-family:'object-fit:cover;';
            pointer-events:none !important;
        }
