/* ------------------------------------------------------------------------------------------------------------------------ */
/* TYPOGRAPHY ------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.typography,
.mce-content-body,
.editor-styles-wrapper .typography {
    color: map-get($colors, _01-01);

    /*
     * Headings
     */

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: map-get($colors, _01-01);
        font-weight: 700;
    }

    /*
     * Strong
     */

    strong {
        font-weight: 700;
    }

    /*
     * Lists
     */

    ul {
        list-style: none;

        li {
            @include relative;
            padding-left: 32px;

            @media screen and (max-width: 768px) {
                padding-left: 24px;
                text-align: left;
            }

            &:not(:last-child) {
                padding-bottom: 1em;
            }

            &:before {
                @include absolute;
                content: "";
                top: 0;
                left: 0;
                margin-top: 10px;
                width: 10px;
                height: 10px;
                background: map-get($colors, _01-01);
                border-radius: 100%;

                @media screen and (max-width: 768px) {
                    margin-top: 8px;
                    width: 9px;
                    height: 9px;
                }
            }
        }
    }

    ol {
        list-style: none;
        counter-reset: ol-counter;

        li {
            @include flex-row;
            counter-increment: ol-counter;

            @media screen and (max-width: 768px) {
                text-align: left;
            }

            &:not(:last-child) {
                padding-bottom: 1em;
            }

            &:before {
                content: counter(ol-counter);
                flex-shrink: 0;
                padding-right: 24px;
                color: map-get($colors, _01-01);
                font-weight: 500;
            }
        }
    }

    /*
     * Anchors
     */

    a:not(.btn):not(.link) {
        @include animate;
        color: map-get($colors, _01-01);
        text-decoration: underline;

        @media screen and (min-width: 769px) {
            &:hover,
            &:focus {
                color: map-get($colors, _01-01);
                text-decoration: none;
            }
        }
    }

    a.btn {
        width: fit-content;
    }

    &.anchor-opacity-underline {
        a {
            &:hover,
            &:focus {
                text-decoration: underline !important;
                opacity: 0.6 !important;
            }
        }
    }

    /*
     * Images
     */

    img[class*="wp-image"] {
        max-width: 100%;
    }

    /*
     * Spacing (Normal)
     */

    h1,
    .h1,
    h2,
    .h2,
    h3,
    .h3,
    h4,
    .h4,
    h5,
    .h5,
    h6,
    .h6,
    p,
    ul,
    ol,
    .btns-wrapper {
        &:not(:first-child) {
            padding-top: 1.5em;

            @media screen and (max-width: 768px) {
                padding-top: 1.25em;
            }
        }
    }

    /*
     * Spacing (Shorter)
     */

    &.half-spacing {
        h1,
        .h1,
        h2,
        .h2,
        h3,
        .h3,
        h4,
        .h4,
        h5,
        .h5,
        h6,
        .h6,
        p,
        ul,
        ol,
        .btns-wrapper {
            &:not(:first-child) {
                @media screen and (min-width: 769px) {
                    padding-top: 1.25em;
                }
            }
        }
    }

    /*
     * Light Tipography
     */

    &.typo-light {
        color: #fff;

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            color: #fff;
        }

        /*
        * Anchors
        */

        a:not(.btn):not(.link) {
            color: #fff;

            @media screen and (min-width: 769px) {
                &:hover,
                &:focus {
                    color: #fff;
                }
            }
        }
    }

    /*
     * Colours
     */

    @each $key, $color in $colors {
        &.typo#{$key} {
            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
                color: #{$color};
            }

            ul li:before {
                background: #{$color};
            }

            ol li:before {
                color: #{$color};
            }

            a:not(.btn):not(.link) {
                color: #{$color};

                @media screen and (min-width: 769px) {
                    &:hover,
                    &:focus {
                        color: #{$color};
                    }
                }
            }
        }
    }

    /*
     * WordPress
     */

    .alignleft {
        float: left;
        padding: 2rem 2rem 2rem 0;
    }
}
